/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #2c7be5;
  --indigo: #4eb7eb;
  --purple: #6b5eae;
  --pink: #9D7BD8;
  --red: #e51c23;
  --orange: #fd7e14;
  --yellow: #ff9800;
  --green: #4CAF50;
  --teal: #02a8b5;
  --cyan: #47BAC1;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #2c7be5;
  --secondary: #9D7BD8;
  --success: #4CAF50;
  --info: #47BAC1;
  --warning: #ff9800;
  --danger: #e51c23;
  --light: #f8f9fa;
  --dark: #354052;
  --tertiary: #5997eb;
  --gray: #6c757d;
  --gray2: #dee2e6;
  --dark-red: #EB5757;
  --dark-green: #A0C8A9;
  --dark-orange: #EE9E07;
  --dark-blue: #2D9CDB;
  --dark-purple: #63619D;
  --light-red: #F6999A;
  --light-green: #93EFA9;
  --light-orange: #F9E6C5;
  --light-blue: #56CCF2;
  --light-purple: #E0DFFF;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1440px;
  --font-family-sans-serif: apercu-extralight;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "apercu-extralight";
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.5;
  color: #495057;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: bold; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #2c7be5;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #1657af;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.2;
  color: #000; }

h1, .h1 {
  font-size: 1.75rem; }

h2, .h2 {
  font-size: 1.53125rem; }

h3, .h3 {
  font-size: 1.3125rem; }

h4, .h4 {
  font-size: 1.09375rem; }

h5, .h5 {
  font-size: 0.875rem; }

h6, .h6 {
  font-size: 0.875rem; }

.lead {
  font-size: 1.09375rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 300; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.3rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #9D7BD8;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.15rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: bold; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1200px; } }
  @media (min-width: 1440px) {
    .container {
      max-width: 1400px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1440px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xxl-first {
    order: -1; }
  .order-xxl-last {
    order: 13; }
  .order-xxl-0 {
    order: 0; }
  .order-xxl-1 {
    order: 1; }
  .order-xxl-2 {
    order: 2; }
  .order-xxl-3 {
    order: 3; }
  .order-xxl-4 {
    order: 4; }
  .order-xxl-5 {
    order: 5; }
  .order-xxl-6 {
    order: 6; }
  .order-xxl-7 {
    order: 7; }
  .order-xxl-8 {
    order: 8; }
  .order-xxl-9 {
    order: 9; }
  .order-xxl-10 {
    order: 10; }
  .order-xxl-11 {
    order: 11; }
  .order-xxl-12 {
    order: 12; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #495057; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f8f9fa; }

.table-hover tbody tr:hover {
  color: #495057;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c4daf8; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #91baf1; }

.table-hover .table-primary:hover {
  background-color: #adccf5; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #adccf5; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #e4daf4; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #ccbaeb; }

.table-hover .table-secondary:hover {
  background-color: #d6c6ee; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #d6c6ee; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #cde9ce; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #a2d5a4; }

.table-hover .table-success:hover {
  background-color: #bbe1bd; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #bbe1bd; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #cbecee; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #9fdbdf; }

.table-hover .table-info:hover {
  background-color: #b8e5e8; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #b8e5e8; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffe2b8; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffc97a; }

.table-hover .table-warning:hover {
  background-color: #ffd89f; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffd89f; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f8bfc1; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f1898d; }

.table-hover .table-danger:hover {
  background-color: #f5a8ab; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f5a8ab; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6cacf; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #969ca5; }

.table-hover .table-dark:hover {
  background-color: #b8bdc3; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b8bdc3; }

.table-tertiary,
.table-tertiary > th,
.table-tertiary > td {
  background-color: #d1e2f9; }

.table-tertiary th,
.table-tertiary td,
.table-tertiary thead th,
.table-tertiary tbody + tbody {
  border-color: #a9c9f4; }

.table-hover .table-tertiary:hover {
  background-color: #bad4f6; }
  .table-hover .table-tertiary:hover > td,
  .table-hover .table-tertiary:hover > th {
    background-color: #bad4f6; }

.table-gray,
.table-gray > th,
.table-gray > td {
  background-color: #d6d8db; }

.table-gray th,
.table-gray td,
.table-gray thead th,
.table-gray tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-gray:hover {
  background-color: #c8cbcf; }
  .table-hover .table-gray:hover > td,
  .table-hover .table-gray:hover > th {
    background-color: #c8cbcf; }

.table-gray2,
.table-gray2 > th,
.table-gray2 > td {
  background-color: #f6f7f8; }

.table-gray2 th,
.table-gray2 td,
.table-gray2 thead th,
.table-gray2 tbody + tbody {
  border-color: #eef0f2; }

.table-hover .table-gray2:hover {
  background-color: #e8eaed; }
  .table-hover .table-gray2:hover > td,
  .table-hover .table-gray2:hover > th {
    background-color: #e8eaed; }

.table-dark-red,
.table-dark-red > th,
.table-dark-red > td {
  background-color: #f9d0d0; }

.table-dark-red th,
.table-dark-red td,
.table-dark-red thead th,
.table-dark-red tbody + tbody {
  border-color: #f5a8a8; }

.table-hover .table-dark-red:hover {
  background-color: #f6b9b9; }
  .table-hover .table-dark-red:hover > td,
  .table-hover .table-dark-red:hover > th {
    background-color: #f6b9b9; }

.table-dark-green,
.table-dark-green > th,
.table-dark-green > td {
  background-color: #e4f0e7; }

.table-dark-green th,
.table-dark-green td,
.table-dark-green thead th,
.table-dark-green tbody + tbody {
  border-color: #cee2d2; }

.table-hover .table-dark-green:hover {
  background-color: #d4e7d8; }
  .table-hover .table-dark-green:hover > td,
  .table-hover .table-dark-green:hover > th {
    background-color: #d4e7d8; }

.table-dark-orange,
.table-dark-orange > th,
.table-dark-orange > td {
  background-color: #fae4ba; }

.table-dark-orange th,
.table-dark-orange td,
.table-dark-orange thead th,
.table-dark-orange tbody + tbody {
  border-color: #f6cd7e; }

.table-hover .table-dark-orange:hover {
  background-color: #f8dba2; }
  .table-hover .table-dark-orange:hover > td,
  .table-hover .table-dark-orange:hover > th {
    background-color: #f8dba2; }

.table-dark-blue,
.table-dark-blue > th,
.table-dark-blue > td {
  background-color: #c4e3f5; }

.table-dark-blue th,
.table-dark-blue td,
.table-dark-blue thead th,
.table-dark-blue tbody + tbody {
  border-color: #92ccec; }

.table-hover .table-dark-blue:hover {
  background-color: #aed9f1; }
  .table-hover .table-dark-blue:hover > td,
  .table-hover .table-dark-blue:hover > th {
    background-color: #aed9f1; }

.table-dark-purple,
.table-dark-purple > th,
.table-dark-purple > td {
  background-color: #d3d3e4; }

.table-dark-purple th,
.table-dark-purple td,
.table-dark-purple thead th,
.table-dark-purple tbody + tbody {
  border-color: #aeadcc; }

.table-hover .table-dark-purple:hover {
  background-color: #c3c3da; }
  .table-hover .table-dark-purple:hover > td,
  .table-hover .table-dark-purple:hover > th {
    background-color: #c3c3da; }

.table-light-red,
.table-light-red > th,
.table-light-red > td {
  background-color: #fce2e3; }

.table-light-red th,
.table-light-red td,
.table-light-red thead th,
.table-light-red tbody + tbody {
  border-color: #facaca; }

.table-hover .table-light-red:hover {
  background-color: #facbcd; }
  .table-hover .table-light-red:hover > td,
  .table-hover .table-light-red:hover > th {
    background-color: #facbcd; }

.table-light-green,
.table-light-green > th,
.table-light-green > td {
  background-color: #e1fbe7; }

.table-light-green th,
.table-light-green td,
.table-light-green thead th,
.table-light-green tbody + tbody {
  border-color: #c7f7d2; }

.table-hover .table-light-green:hover {
  background-color: #cbf8d5; }
  .table-hover .table-light-green:hover > td,
  .table-hover .table-light-green:hover > th {
    background-color: #cbf8d5; }

.table-light-orange,
.table-light-orange > th,
.table-light-orange > td {
  background-color: #fdf8ef; }

.table-light-orange th,
.table-light-orange td,
.table-light-orange thead th,
.table-light-orange tbody + tbody {
  border-color: #fcf2e1; }

.table-hover .table-light-orange:hover {
  background-color: #faeed8; }
  .table-hover .table-light-orange:hover > td,
  .table-hover .table-light-orange:hover > th {
    background-color: #faeed8; }

.table-light-blue,
.table-light-blue > th,
.table-light-blue > td {
  background-color: #d0f1fb; }

.table-light-blue th,
.table-light-blue td,
.table-light-blue thead th,
.table-light-blue tbody + tbody {
  border-color: #a7e4f8; }

.table-hover .table-light-blue:hover {
  background-color: #b9eaf9; }
  .table-hover .table-light-blue:hover > td,
  .table-hover .table-light-blue:hover > th {
    background-color: #b9eaf9; }

.table-light-purple,
.table-light-purple > th,
.table-light-purple > td {
  background-color: #f6f6ff; }

.table-light-purple th,
.table-light-purple td,
.table-light-purple thead th,
.table-light-purple tbody + tbody {
  border-color: #efeeff; }

.table-hover .table-light-purple:hover {
  background-color: #ddddff; }
  .table-hover .table-light-purple:hover > td,
  .table-hover .table-light-purple:hover > th {
    background-color: #ddddff; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

@media (max-width: 1439.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xxl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.7rem;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.3rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #9ec2f3;
    outline: 0;
    box-shadow: 0 0 0 0.02rem rgba(44, 123, 229, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.35rem + 1px);
  padding-bottom: calc(0.35rem + 1px);
  font-size: 1rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.15rem + 1px);
  padding-bottom: calc(0.15rem + 1px);
  font-size: 0.75rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #495057;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.425rem + 2px);
  padding: 0.15rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0.15rem; }

.form-control-lg {
  height: calc(2.2rem + 2px);
  padding: 0.35rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.45rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #ced4da; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.75rem;
  line-height: 1.5;
  color: #212529;
  background-color: rgba(206, 212, 218, 0.9);
  border-radius: 0.3rem; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #ced4da; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #ced4da;
    box-shadow: 0 0 0 0.02rem rgba(206, 212, 218, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #ced4da; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #ced4da;
    box-shadow: 0 0 0 0.02rem rgba(206, 212, 218, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #ced4da; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #ced4da; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #ced4da; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #ebeef0;
  background-color: #ebeef0; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.02rem rgba(206, 212, 218, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #ced4da; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #ced4da; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #ced4da;
  box-shadow: 0 0 0 0.02rem rgba(206, 212, 218, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e51c23; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.75rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(229, 28, 35, 0.9);
  border-radius: 0.3rem; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #e51c23; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #e51c23;
    box-shadow: 0 0 0 0.02rem rgba(229, 28, 35, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #e51c23; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #e51c23;
    box-shadow: 0 0 0 0.02rem rgba(229, 28, 35, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #e51c23; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #e51c23; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #e51c23; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #ea4a4f;
  background-color: #ea4a4f; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.02rem rgba(229, 28, 35, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #e51c23; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #e51c23; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #e51c23;
  box-shadow: 0 0 0 0.02rem rgba(229, 28, 35, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 300;
  color: #495057;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.25rem 0.7rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.3rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #495057;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.02rem rgba(44, 123, 229, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #2c7be5;
  border-color: #2c7be5; }
  .btn-primary:hover {
    color: #fff;
    background-color: #1a68d1;
    border-color: #1862c6; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.02rem rgba(76, 143, 233, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #2c7be5;
    border-color: #2c7be5; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #1862c6;
    border-color: #175dba; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(76, 143, 233, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #9D7BD8;
  border-color: #9D7BD8; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #875dcf;
    border-color: #8054cc; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.02rem rgba(172, 143, 222, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #9D7BD8;
    border-color: #9D7BD8; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #8054cc;
    border-color: #784ac9; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(172, 143, 222, 0.5); }

.btn-success {
  color: #fff;
  background-color: #4CAF50;
  border-color: #4CAF50; }
  .btn-success:hover {
    color: #fff;
    background-color: #409444;
    border-color: #3d8b40; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.02rem rgba(103, 187, 106, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #4CAF50;
    border-color: #4CAF50; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3d8b40;
    border-color: #39833c; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(103, 187, 106, 0.5); }

.btn-info {
  color: #212529;
  background-color: #47BAC1;
  border-color: #47BAC1; }
  .btn-info:hover {
    color: #fff;
    background-color: #39a2a9;
    border-color: #36999f; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.02rem rgba(65, 164, 170, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #212529;
    background-color: #47BAC1;
    border-color: #47BAC1; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #36999f;
    border-color: #329096; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(65, 164, 170, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800; }
  .btn-warning:hover {
    color: #fff;
    background-color: #d98100;
    border-color: #cc7a00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.02rem rgba(222, 135, 6, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cc7a00;
    border-color: #bf7200; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(222, 135, 6, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #e51c23;
  border-color: #e51c23; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c4161d;
    border-color: #b9151b; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.02rem rgba(233, 62, 68, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #e51c23;
    border-color: #e51c23; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #b9151b;
    border-color: #ad1419; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(233, 62, 68, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.02rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #354052;
  border-color: #354052; }
  .btn-dark:hover {
    color: #fff;
    background-color: #262e3b;
    border-color: #212833; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.02rem rgba(83, 93, 108, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #354052;
    border-color: #354052; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #212833;
    border-color: #1c222b; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(83, 93, 108, 0.5); }

.btn-tertiary {
  color: #fff;
  background-color: #5997eb;
  border-color: #5997eb; }
  .btn-tertiary:hover {
    color: #fff;
    background-color: #3782e6;
    border-color: #2c7be5; }
  .btn-tertiary:focus, .btn-tertiary.focus {
    box-shadow: 0 0 0 0.02rem rgba(114, 167, 238, 0.5); }
  .btn-tertiary.disabled, .btn-tertiary:disabled {
    color: #fff;
    background-color: #5997eb;
    border-color: #5997eb; }
  .btn-tertiary:not(:disabled):not(.disabled):active, .btn-tertiary:not(:disabled):not(.disabled).active,
  .show > .btn-tertiary.dropdown-toggle {
    color: #fff;
    background-color: #2c7be5;
    border-color: #2174e4; }
    .btn-tertiary:not(:disabled):not(.disabled):active:focus, .btn-tertiary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-tertiary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(114, 167, 238, 0.5); }

.btn-gray {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-gray:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-gray:focus, .btn-gray.focus {
    box-shadow: 0 0 0 0.02rem rgba(130, 138, 145, 0.5); }
  .btn-gray.disabled, .btn-gray:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-gray:not(:disabled):not(.disabled):active, .btn-gray:not(:disabled):not(.disabled).active,
  .show > .btn-gray.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-gray:not(:disabled):not(.disabled):active:focus, .btn-gray:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(130, 138, 145, 0.5); }

.btn-gray2 {
  color: #212529;
  background-color: #dee2e6;
  border-color: #dee2e6; }
  .btn-gray2:hover {
    color: #212529;
    background-color: #c8cfd6;
    border-color: #c1c9d0; }
  .btn-gray2:focus, .btn-gray2.focus {
    box-shadow: 0 0 0 0.02rem rgba(194, 198, 202, 0.5); }
  .btn-gray2.disabled, .btn-gray2:disabled {
    color: #212529;
    background-color: #dee2e6;
    border-color: #dee2e6; }
  .btn-gray2:not(:disabled):not(.disabled):active, .btn-gray2:not(:disabled):not(.disabled).active,
  .show > .btn-gray2.dropdown-toggle {
    color: #212529;
    background-color: #c1c9d0;
    border-color: #bac2cb; }
    .btn-gray2:not(:disabled):not(.disabled):active:focus, .btn-gray2:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray2.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(194, 198, 202, 0.5); }

.btn-dark-red {
  color: #fff;
  background-color: #EB5757;
  border-color: #EB5757; }
  .btn-dark-red:hover {
    color: #fff;
    background-color: #e73535;
    border-color: #e62929; }
  .btn-dark-red:focus, .btn-dark-red.focus {
    box-shadow: 0 0 0 0.02rem rgba(238, 112, 112, 0.5); }
  .btn-dark-red.disabled, .btn-dark-red:disabled {
    color: #fff;
    background-color: #EB5757;
    border-color: #EB5757; }
  .btn-dark-red:not(:disabled):not(.disabled):active, .btn-dark-red:not(:disabled):not(.disabled).active,
  .show > .btn-dark-red.dropdown-toggle {
    color: #fff;
    background-color: #e62929;
    border-color: #e41e1e; }
    .btn-dark-red:not(:disabled):not(.disabled):active:focus, .btn-dark-red:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark-red.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(238, 112, 112, 0.5); }

.btn-dark-green {
  color: #212529;
  background-color: #A0C8A9;
  border-color: #A0C8A9; }
  .btn-dark-green:hover {
    color: #212529;
    background-color: #88ba93;
    border-color: #80b58c; }
  .btn-dark-green:focus, .btn-dark-green.focus {
    box-shadow: 0 0 0 0.02rem rgba(141, 176, 150, 0.5); }
  .btn-dark-green.disabled, .btn-dark-green:disabled {
    color: #212529;
    background-color: #A0C8A9;
    border-color: #A0C8A9; }
  .btn-dark-green:not(:disabled):not(.disabled):active, .btn-dark-green:not(:disabled):not(.disabled).active,
  .show > .btn-dark-green.dropdown-toggle {
    color: #212529;
    background-color: #80b58c;
    border-color: #78b184; }
    .btn-dark-green:not(:disabled):not(.disabled):active:focus, .btn-dark-green:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark-green.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(141, 176, 150, 0.5); }

.btn-dark-orange {
  color: #212529;
  background-color: #EE9E07;
  border-color: #EE9E07; }
  .btn-dark-orange:hover {
    color: #fff;
    background-color: #c98506;
    border-color: #bc7d06; }
  .btn-dark-orange:focus, .btn-dark-orange.focus {
    box-shadow: 0 0 0 0.02rem rgba(207, 140, 12, 0.5); }
  .btn-dark-orange.disabled, .btn-dark-orange:disabled {
    color: #212529;
    background-color: #EE9E07;
    border-color: #EE9E07; }
  .btn-dark-orange:not(:disabled):not(.disabled):active, .btn-dark-orange:not(:disabled):not(.disabled).active,
  .show > .btn-dark-orange.dropdown-toggle {
    color: #fff;
    background-color: #bc7d06;
    border-color: #b07505; }
    .btn-dark-orange:not(:disabled):not(.disabled):active:focus, .btn-dark-orange:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark-orange.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(207, 140, 12, 0.5); }

.btn-dark-blue {
  color: #fff;
  background-color: #2D9CDB;
  border-color: #2D9CDB; }
  .btn-dark-blue:hover {
    color: #fff;
    background-color: #2187c1;
    border-color: #1f7fb6; }
  .btn-dark-blue:focus, .btn-dark-blue.focus {
    box-shadow: 0 0 0 0.02rem rgba(77, 171, 224, 0.5); }
  .btn-dark-blue.disabled, .btn-dark-blue:disabled {
    color: #fff;
    background-color: #2D9CDB;
    border-color: #2D9CDB; }
  .btn-dark-blue:not(:disabled):not(.disabled):active, .btn-dark-blue:not(:disabled):not(.disabled).active,
  .show > .btn-dark-blue.dropdown-toggle {
    color: #fff;
    background-color: #1f7fb6;
    border-color: #1d78ab; }
    .btn-dark-blue:not(:disabled):not(.disabled):active:focus, .btn-dark-blue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark-blue.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(77, 171, 224, 0.5); }

.btn-dark-purple {
  color: #fff;
  background-color: #63619D;
  border-color: #63619D; }
  .btn-dark-purple:hover {
    color: #fff;
    background-color: #545285;
    border-color: #4f4e7d; }
  .btn-dark-purple:focus, .btn-dark-purple.focus {
    box-shadow: 0 0 0 0.02rem rgba(122, 121, 172, 0.5); }
  .btn-dark-purple.disabled, .btn-dark-purple:disabled {
    color: #fff;
    background-color: #63619D;
    border-color: #63619D; }
  .btn-dark-purple:not(:disabled):not(.disabled):active, .btn-dark-purple:not(:disabled):not(.disabled).active,
  .show > .btn-dark-purple.dropdown-toggle {
    color: #fff;
    background-color: #4f4e7d;
    border-color: #4a4976; }
    .btn-dark-purple:not(:disabled):not(.disabled):active:focus, .btn-dark-purple:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark-purple.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(122, 121, 172, 0.5); }

.btn-light-red {
  color: #212529;
  background-color: #F6999A;
  border-color: #F6999A; }
  .btn-light-red:hover {
    color: #212529;
    background-color: #f37677;
    border-color: #f26a6c; }
  .btn-light-red:focus, .btn-light-red.focus {
    box-shadow: 0 0 0 0.02rem rgba(214, 136, 137, 0.5); }
  .btn-light-red.disabled, .btn-light-red:disabled {
    color: #212529;
    background-color: #F6999A;
    border-color: #F6999A; }
  .btn-light-red:not(:disabled):not(.disabled):active, .btn-light-red:not(:disabled):not(.disabled).active,
  .show > .btn-light-red.dropdown-toggle {
    color: #fff;
    background-color: #f26a6c;
    border-color: #f15e60; }
    .btn-light-red:not(:disabled):not(.disabled):active:focus, .btn-light-red:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light-red.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(214, 136, 137, 0.5); }

.btn-light-green {
  color: #212529;
  background-color: #93EFA9;
  border-color: #93EFA9; }
  .btn-light-green:hover {
    color: #212529;
    background-color: #72ea8e;
    border-color: #67e886; }
  .btn-light-green:focus, .btn-light-green.focus {
    box-shadow: 0 0 0 0.02rem rgba(130, 209, 150, 0.5); }
  .btn-light-green.disabled, .btn-light-green:disabled {
    color: #212529;
    background-color: #93EFA9;
    border-color: #93EFA9; }
  .btn-light-green:not(:disabled):not(.disabled):active, .btn-light-green:not(:disabled):not(.disabled).active,
  .show > .btn-light-green.dropdown-toggle {
    color: #212529;
    background-color: #67e886;
    border-color: #5be77d; }
    .btn-light-green:not(:disabled):not(.disabled):active:focus, .btn-light-green:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light-green.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(130, 209, 150, 0.5); }

.btn-light-orange {
  color: #212529;
  background-color: #F9E6C5;
  border-color: #F9E6C5; }
  .btn-light-orange:hover {
    color: #212529;
    background-color: #f5d7a2;
    border-color: #f4d297; }
  .btn-light-orange:focus, .btn-light-orange.focus {
    box-shadow: 0 0 0 0.02rem rgba(217, 201, 174, 0.5); }
  .btn-light-orange.disabled, .btn-light-orange:disabled {
    color: #212529;
    background-color: #F9E6C5;
    border-color: #F9E6C5; }
  .btn-light-orange:not(:disabled):not(.disabled):active, .btn-light-orange:not(:disabled):not(.disabled).active,
  .show > .btn-light-orange.dropdown-toggle {
    color: #212529;
    background-color: #f4d297;
    border-color: #f3cd8b; }
    .btn-light-orange:not(:disabled):not(.disabled):active:focus, .btn-light-orange:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light-orange.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(217, 201, 174, 0.5); }

.btn-light-blue {
  color: #212529;
  background-color: #56CCF2;
  border-color: #56CCF2; }
  .btn-light-blue:hover {
    color: #212529;
    background-color: #32c1ef;
    border-color: #27beee; }
  .btn-light-blue:focus, .btn-light-blue.focus {
    box-shadow: 0 0 0 0.02rem rgba(78, 179, 212, 0.5); }
  .btn-light-blue.disabled, .btn-light-blue:disabled {
    color: #212529;
    background-color: #56CCF2;
    border-color: #56CCF2; }
  .btn-light-blue:not(:disabled):not(.disabled):active, .btn-light-blue:not(:disabled):not(.disabled).active,
  .show > .btn-light-blue.dropdown-toggle {
    color: #212529;
    background-color: #27beee;
    border-color: #1bbaed; }
    .btn-light-blue:not(:disabled):not(.disabled):active:focus, .btn-light-blue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light-blue.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(78, 179, 212, 0.5); }

.btn-light-purple {
  color: #212529;
  background-color: #E0DFFF;
  border-color: #E0DFFF; }
  .btn-light-purple:hover {
    color: #212529;
    background-color: #bbb9ff;
    border-color: #afacff; }
  .btn-light-purple:focus, .btn-light-purple.focus {
    box-shadow: 0 0 0 0.02rem rgba(195, 195, 223, 0.5); }
  .btn-light-purple.disabled, .btn-light-purple:disabled {
    color: #212529;
    background-color: #E0DFFF;
    border-color: #E0DFFF; }
  .btn-light-purple:not(:disabled):not(.disabled):active, .btn-light-purple:not(:disabled):not(.disabled).active,
  .show > .btn-light-purple.dropdown-toggle {
    color: #212529;
    background-color: #afacff;
    border-color: #a29fff; }
    .btn-light-purple:not(:disabled):not(.disabled):active:focus, .btn-light-purple:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light-purple.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(195, 195, 223, 0.5); }

.btn-outline-primary {
  color: #2c7be5;
  border-color: #2c7be5; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #2c7be5;
    border-color: #2c7be5; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.02rem rgba(44, 123, 229, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #2c7be5;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #2c7be5;
    border-color: #2c7be5; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(44, 123, 229, 0.5); }

.btn-outline-secondary {
  color: #9D7BD8;
  border-color: #9D7BD8; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #9D7BD8;
    border-color: #9D7BD8; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.02rem rgba(157, 123, 216, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #9D7BD8;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #9D7BD8;
    border-color: #9D7BD8; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(157, 123, 216, 0.5); }

.btn-outline-success {
  color: #4CAF50;
  border-color: #4CAF50; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #4CAF50;
    border-color: #4CAF50; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.02rem rgba(76, 175, 80, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #4CAF50;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #4CAF50;
    border-color: #4CAF50; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(76, 175, 80, 0.5); }

.btn-outline-info {
  color: #47BAC1;
  border-color: #47BAC1; }
  .btn-outline-info:hover {
    color: #212529;
    background-color: #47BAC1;
    border-color: #47BAC1; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.02rem rgba(71, 186, 193, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #47BAC1;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #212529;
    background-color: #47BAC1;
    border-color: #47BAC1; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(71, 186, 193, 0.5); }

.btn-outline-warning {
  color: #ff9800;
  border-color: #ff9800; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.02rem rgba(255, 152, 0, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ff9800;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(255, 152, 0, 0.5); }

.btn-outline-danger {
  color: #e51c23;
  border-color: #e51c23; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #e51c23;
    border-color: #e51c23; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.02rem rgba(229, 28, 35, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #e51c23;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #e51c23;
    border-color: #e51c23; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(229, 28, 35, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.02rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #354052;
  border-color: #354052; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #354052;
    border-color: #354052; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.02rem rgba(53, 64, 82, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #354052;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #354052;
    border-color: #354052; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(53, 64, 82, 0.5); }

.btn-outline-tertiary {
  color: #5997eb;
  border-color: #5997eb; }
  .btn-outline-tertiary:hover {
    color: #fff;
    background-color: #5997eb;
    border-color: #5997eb; }
  .btn-outline-tertiary:focus, .btn-outline-tertiary.focus {
    box-shadow: 0 0 0 0.02rem rgba(89, 151, 235, 0.5); }
  .btn-outline-tertiary.disabled, .btn-outline-tertiary:disabled {
    color: #5997eb;
    background-color: transparent; }
  .btn-outline-tertiary:not(:disabled):not(.disabled):active, .btn-outline-tertiary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-tertiary.dropdown-toggle {
    color: #fff;
    background-color: #5997eb;
    border-color: #5997eb; }
    .btn-outline-tertiary:not(:disabled):not(.disabled):active:focus, .btn-outline-tertiary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-tertiary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(89, 151, 235, 0.5); }

.btn-outline-gray {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-gray:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-gray:focus, .btn-outline-gray.focus {
    box-shadow: 0 0 0 0.02rem rgba(108, 117, 125, 0.5); }
  .btn-outline-gray.disabled, .btn-outline-gray:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-gray:not(:disabled):not(.disabled):active, .btn-outline-gray:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-gray:not(:disabled):not(.disabled):active:focus, .btn-outline-gray:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(108, 117, 125, 0.5); }

.btn-outline-gray2 {
  color: #dee2e6;
  border-color: #dee2e6; }
  .btn-outline-gray2:hover {
    color: #212529;
    background-color: #dee2e6;
    border-color: #dee2e6; }
  .btn-outline-gray2:focus, .btn-outline-gray2.focus {
    box-shadow: 0 0 0 0.02rem rgba(222, 226, 230, 0.5); }
  .btn-outline-gray2.disabled, .btn-outline-gray2:disabled {
    color: #dee2e6;
    background-color: transparent; }
  .btn-outline-gray2:not(:disabled):not(.disabled):active, .btn-outline-gray2:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray2.dropdown-toggle {
    color: #212529;
    background-color: #dee2e6;
    border-color: #dee2e6; }
    .btn-outline-gray2:not(:disabled):not(.disabled):active:focus, .btn-outline-gray2:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray2.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(222, 226, 230, 0.5); }

.btn-outline-dark-red {
  color: #EB5757;
  border-color: #EB5757; }
  .btn-outline-dark-red:hover {
    color: #fff;
    background-color: #EB5757;
    border-color: #EB5757; }
  .btn-outline-dark-red:focus, .btn-outline-dark-red.focus {
    box-shadow: 0 0 0 0.02rem rgba(235, 87, 87, 0.5); }
  .btn-outline-dark-red.disabled, .btn-outline-dark-red:disabled {
    color: #EB5757;
    background-color: transparent; }
  .btn-outline-dark-red:not(:disabled):not(.disabled):active, .btn-outline-dark-red:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark-red.dropdown-toggle {
    color: #fff;
    background-color: #EB5757;
    border-color: #EB5757; }
    .btn-outline-dark-red:not(:disabled):not(.disabled):active:focus, .btn-outline-dark-red:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark-red.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(235, 87, 87, 0.5); }

.btn-outline-dark-green {
  color: #A0C8A9;
  border-color: #A0C8A9; }
  .btn-outline-dark-green:hover {
    color: #212529;
    background-color: #A0C8A9;
    border-color: #A0C8A9; }
  .btn-outline-dark-green:focus, .btn-outline-dark-green.focus {
    box-shadow: 0 0 0 0.02rem rgba(160, 200, 169, 0.5); }
  .btn-outline-dark-green.disabled, .btn-outline-dark-green:disabled {
    color: #A0C8A9;
    background-color: transparent; }
  .btn-outline-dark-green:not(:disabled):not(.disabled):active, .btn-outline-dark-green:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark-green.dropdown-toggle {
    color: #212529;
    background-color: #A0C8A9;
    border-color: #A0C8A9; }
    .btn-outline-dark-green:not(:disabled):not(.disabled):active:focus, .btn-outline-dark-green:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark-green.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(160, 200, 169, 0.5); }

.btn-outline-dark-orange {
  color: #EE9E07;
  border-color: #EE9E07; }
  .btn-outline-dark-orange:hover {
    color: #212529;
    background-color: #EE9E07;
    border-color: #EE9E07; }
  .btn-outline-dark-orange:focus, .btn-outline-dark-orange.focus {
    box-shadow: 0 0 0 0.02rem rgba(238, 158, 7, 0.5); }
  .btn-outline-dark-orange.disabled, .btn-outline-dark-orange:disabled {
    color: #EE9E07;
    background-color: transparent; }
  .btn-outline-dark-orange:not(:disabled):not(.disabled):active, .btn-outline-dark-orange:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark-orange.dropdown-toggle {
    color: #212529;
    background-color: #EE9E07;
    border-color: #EE9E07; }
    .btn-outline-dark-orange:not(:disabled):not(.disabled):active:focus, .btn-outline-dark-orange:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark-orange.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(238, 158, 7, 0.5); }

.btn-outline-dark-blue {
  color: #2D9CDB;
  border-color: #2D9CDB; }
  .btn-outline-dark-blue:hover {
    color: #fff;
    background-color: #2D9CDB;
    border-color: #2D9CDB; }
  .btn-outline-dark-blue:focus, .btn-outline-dark-blue.focus {
    box-shadow: 0 0 0 0.02rem rgba(45, 156, 219, 0.5); }
  .btn-outline-dark-blue.disabled, .btn-outline-dark-blue:disabled {
    color: #2D9CDB;
    background-color: transparent; }
  .btn-outline-dark-blue:not(:disabled):not(.disabled):active, .btn-outline-dark-blue:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark-blue.dropdown-toggle {
    color: #fff;
    background-color: #2D9CDB;
    border-color: #2D9CDB; }
    .btn-outline-dark-blue:not(:disabled):not(.disabled):active:focus, .btn-outline-dark-blue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark-blue.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(45, 156, 219, 0.5); }

.btn-outline-dark-purple {
  color: #63619D;
  border-color: #63619D; }
  .btn-outline-dark-purple:hover {
    color: #fff;
    background-color: #63619D;
    border-color: #63619D; }
  .btn-outline-dark-purple:focus, .btn-outline-dark-purple.focus {
    box-shadow: 0 0 0 0.02rem rgba(99, 97, 157, 0.5); }
  .btn-outline-dark-purple.disabled, .btn-outline-dark-purple:disabled {
    color: #63619D;
    background-color: transparent; }
  .btn-outline-dark-purple:not(:disabled):not(.disabled):active, .btn-outline-dark-purple:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark-purple.dropdown-toggle {
    color: #fff;
    background-color: #63619D;
    border-color: #63619D; }
    .btn-outline-dark-purple:not(:disabled):not(.disabled):active:focus, .btn-outline-dark-purple:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark-purple.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(99, 97, 157, 0.5); }

.btn-outline-light-red {
  color: #F6999A;
  border-color: #F6999A; }
  .btn-outline-light-red:hover {
    color: #212529;
    background-color: #F6999A;
    border-color: #F6999A; }
  .btn-outline-light-red:focus, .btn-outline-light-red.focus {
    box-shadow: 0 0 0 0.02rem rgba(246, 153, 154, 0.5); }
  .btn-outline-light-red.disabled, .btn-outline-light-red:disabled {
    color: #F6999A;
    background-color: transparent; }
  .btn-outline-light-red:not(:disabled):not(.disabled):active, .btn-outline-light-red:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light-red.dropdown-toggle {
    color: #212529;
    background-color: #F6999A;
    border-color: #F6999A; }
    .btn-outline-light-red:not(:disabled):not(.disabled):active:focus, .btn-outline-light-red:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light-red.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(246, 153, 154, 0.5); }

.btn-outline-light-green {
  color: #93EFA9;
  border-color: #93EFA9; }
  .btn-outline-light-green:hover {
    color: #212529;
    background-color: #93EFA9;
    border-color: #93EFA9; }
  .btn-outline-light-green:focus, .btn-outline-light-green.focus {
    box-shadow: 0 0 0 0.02rem rgba(147, 239, 169, 0.5); }
  .btn-outline-light-green.disabled, .btn-outline-light-green:disabled {
    color: #93EFA9;
    background-color: transparent; }
  .btn-outline-light-green:not(:disabled):not(.disabled):active, .btn-outline-light-green:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light-green.dropdown-toggle {
    color: #212529;
    background-color: #93EFA9;
    border-color: #93EFA9; }
    .btn-outline-light-green:not(:disabled):not(.disabled):active:focus, .btn-outline-light-green:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light-green.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(147, 239, 169, 0.5); }

.btn-outline-light-orange {
  color: #F9E6C5;
  border-color: #F9E6C5; }
  .btn-outline-light-orange:hover {
    color: #212529;
    background-color: #F9E6C5;
    border-color: #F9E6C5; }
  .btn-outline-light-orange:focus, .btn-outline-light-orange.focus {
    box-shadow: 0 0 0 0.02rem rgba(249, 230, 197, 0.5); }
  .btn-outline-light-orange.disabled, .btn-outline-light-orange:disabled {
    color: #F9E6C5;
    background-color: transparent; }
  .btn-outline-light-orange:not(:disabled):not(.disabled):active, .btn-outline-light-orange:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light-orange.dropdown-toggle {
    color: #212529;
    background-color: #F9E6C5;
    border-color: #F9E6C5; }
    .btn-outline-light-orange:not(:disabled):not(.disabled):active:focus, .btn-outline-light-orange:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light-orange.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(249, 230, 197, 0.5); }

.btn-outline-light-blue {
  color: #56CCF2;
  border-color: #56CCF2; }
  .btn-outline-light-blue:hover {
    color: #212529;
    background-color: #56CCF2;
    border-color: #56CCF2; }
  .btn-outline-light-blue:focus, .btn-outline-light-blue.focus {
    box-shadow: 0 0 0 0.02rem rgba(86, 204, 242, 0.5); }
  .btn-outline-light-blue.disabled, .btn-outline-light-blue:disabled {
    color: #56CCF2;
    background-color: transparent; }
  .btn-outline-light-blue:not(:disabled):not(.disabled):active, .btn-outline-light-blue:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light-blue.dropdown-toggle {
    color: #212529;
    background-color: #56CCF2;
    border-color: #56CCF2; }
    .btn-outline-light-blue:not(:disabled):not(.disabled):active:focus, .btn-outline-light-blue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light-blue.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(86, 204, 242, 0.5); }

.btn-outline-light-purple {
  color: #E0DFFF;
  border-color: #E0DFFF; }
  .btn-outline-light-purple:hover {
    color: #212529;
    background-color: #E0DFFF;
    border-color: #E0DFFF; }
  .btn-outline-light-purple:focus, .btn-outline-light-purple.focus {
    box-shadow: 0 0 0 0.02rem rgba(224, 223, 255, 0.5); }
  .btn-outline-light-purple.disabled, .btn-outline-light-purple:disabled {
    color: #E0DFFF;
    background-color: transparent; }
  .btn-outline-light-purple:not(:disabled):not(.disabled):active, .btn-outline-light-purple:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light-purple.dropdown-toggle {
    color: #212529;
    background-color: #E0DFFF;
    border-color: #E0DFFF; }
    .btn-outline-light-purple:not(:disabled):not(.disabled):active:focus, .btn-outline-light-purple:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light-purple.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(224, 223, 255, 0.5); }

.btn-link {
  font-weight: 300;
  color: #2c7be5;
  text-decoration: none; }
  .btn-link:hover {
    color: #1657af;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.6rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.45rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.15rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0.15rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #495057;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.3rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

@media (min-width: 1440px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xxl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.35rem 1.5rem;
  clear: both;
  font-weight: 300;
  color: #495057;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #2c7be5; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.75rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.35rem 1.5rem;
  color: #495057; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.525rem;
  padding-left: 0.525rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.7rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.3rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(2.2rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.35rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.45rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.425rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.15rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0.15rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.7rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #343a40;
    border-color: #adb5bd;
    background-color: #fff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.02rem rgba(44, 123, 229, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #9ec2f3; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #cbdef9;
    border-color: #cbdef9; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.15625rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #dee2e6;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.15625rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.3rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23343a40' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #2c7be5;
  background-color: #2c7be5; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23343a40' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(44, 123, 229, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(44, 123, 229, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23343a40'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(44, 123, 229, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.15625rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #dee2e6;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(44, 123, 229, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 1.7rem 0.25rem 0.7rem;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.7rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.3rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #9ec2f3;
    outline: 0;
    box-shadow: 0 0 0 0.02rem rgba(44, 123, 229, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.7rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }

.custom-select-sm {
  height: calc(1.425rem + 2px);
  padding-top: 0.15rem;
  padding-bottom: 0.15rem;
  padding-left: 0.5rem;
  font-size: 0.75rem; }

.custom-select-lg {
  height: calc(2.2rem + 2px);
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
  padding-left: 1rem;
  font-size: 1rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.8125rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.8125rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #9ec2f3;
    box-shadow: 0 0 0 0.02rem rgba(44, 123, 229, 0.25); }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.7rem;
  font-weight: 300;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.3rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: 1.8125rem;
    padding: 0.25rem 0.7rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.3rem 0.3rem 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.04rem);
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.02rem rgba(44, 123, 229, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.02rem rgba(44, 123, 229, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.02rem rgba(44, 123, 229, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #2c7be5;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #cbdef9; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #2c7be5;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #cbdef9; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.02rem;
    margin-left: 0.02rem;
    background-color: #2c7be5;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #cbdef9; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.3rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #2c7be5; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1.25rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
  margin-right: 1.25rem;
  font-size: 1.15rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.3rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

@media (max-width: 1439.98px) {
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1440px) {
  .navbar-expand-xxl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xxl > .container,
    .navbar-expand-xxl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid transparent;
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 0 solid transparent; }
  .card-header:first-child {
    border-radius: 0.25rem 0.25rem 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border-top: 0 solid transparent; }
  .card-footer:last-child {
    border-radius: 0 0 0.25rem 0.25rem; }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: 0.25rem; }

.card-img-top {
  width: 100%;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem; }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-top,
          .card-group > .card:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-bottom,
          .card-group > .card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-top,
          .card-group > .card:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-bottom,
          .card-group > .card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion > .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card .card-header {
    margin-bottom: 0; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.3rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.3rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.3rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #6c757d;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #343a40;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.02rem rgba(44, 123, 229, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #2c7be5;
  border-color: #2c7be5; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.35rem 1rem;
  font-size: 1rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.45rem;
  border-bottom-left-radius: 0.45rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.45rem;
  border-bottom-right-radius: 0.45rem; }

.pagination-sm .page-link {
  padding: 0.15rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.15rem;
  border-bottom-left-radius: 0.15rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.15rem;
  border-bottom-right-radius: 0.15rem; }

.badge {
  display: inline-block;
  padding: 0.3em 0.45em;
  font-size: 80%;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.3rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.65em;
  padding-left: 0.65em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #2c7be5; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #1862c6; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.02rem rgba(44, 123, 229, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #9D7BD8; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #8054cc; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.02rem rgba(157, 123, 216, 0.5); }

.badge-success {
  color: #fff;
  background-color: #4CAF50; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #3d8b40; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.02rem rgba(76, 175, 80, 0.5); }

.badge-info {
  color: #212529;
  background-color: #47BAC1; }
  a.badge-info:hover, a.badge-info:focus {
    color: #212529;
    background-color: #36999f; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.02rem rgba(71, 186, 193, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ff9800; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #cc7a00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.02rem rgba(255, 152, 0, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #e51c23; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #b9151b; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.02rem rgba(229, 28, 35, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.02rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #354052; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #212833; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.02rem rgba(53, 64, 82, 0.5); }

.badge-tertiary {
  color: #fff;
  background-color: #5997eb; }
  a.badge-tertiary:hover, a.badge-tertiary:focus {
    color: #fff;
    background-color: #2c7be5; }
  a.badge-tertiary:focus, a.badge-tertiary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.02rem rgba(89, 151, 235, 0.5); }

.badge-gray {
  color: #fff;
  background-color: #6c757d; }
  a.badge-gray:hover, a.badge-gray:focus {
    color: #fff;
    background-color: #545b62; }
  a.badge-gray:focus, a.badge-gray.focus {
    outline: 0;
    box-shadow: 0 0 0 0.02rem rgba(108, 117, 125, 0.5); }

.badge-gray2 {
  color: #212529;
  background-color: #dee2e6; }
  a.badge-gray2:hover, a.badge-gray2:focus {
    color: #212529;
    background-color: #c1c9d0; }
  a.badge-gray2:focus, a.badge-gray2.focus {
    outline: 0;
    box-shadow: 0 0 0 0.02rem rgba(222, 226, 230, 0.5); }

.badge-dark-red {
  color: #fff;
  background-color: #EB5757; }
  a.badge-dark-red:hover, a.badge-dark-red:focus {
    color: #fff;
    background-color: #e62929; }
  a.badge-dark-red:focus, a.badge-dark-red.focus {
    outline: 0;
    box-shadow: 0 0 0 0.02rem rgba(235, 87, 87, 0.5); }

.badge-dark-green {
  color: #212529;
  background-color: #A0C8A9; }
  a.badge-dark-green:hover, a.badge-dark-green:focus {
    color: #212529;
    background-color: #80b58c; }
  a.badge-dark-green:focus, a.badge-dark-green.focus {
    outline: 0;
    box-shadow: 0 0 0 0.02rem rgba(160, 200, 169, 0.5); }

.badge-dark-orange {
  color: #212529;
  background-color: #EE9E07; }
  a.badge-dark-orange:hover, a.badge-dark-orange:focus {
    color: #212529;
    background-color: #bc7d06; }
  a.badge-dark-orange:focus, a.badge-dark-orange.focus {
    outline: 0;
    box-shadow: 0 0 0 0.02rem rgba(238, 158, 7, 0.5); }

.badge-dark-blue {
  color: #fff;
  background-color: #2D9CDB; }
  a.badge-dark-blue:hover, a.badge-dark-blue:focus {
    color: #fff;
    background-color: #1f7fb6; }
  a.badge-dark-blue:focus, a.badge-dark-blue.focus {
    outline: 0;
    box-shadow: 0 0 0 0.02rem rgba(45, 156, 219, 0.5); }

.badge-dark-purple {
  color: #fff;
  background-color: #63619D; }
  a.badge-dark-purple:hover, a.badge-dark-purple:focus {
    color: #fff;
    background-color: #4f4e7d; }
  a.badge-dark-purple:focus, a.badge-dark-purple.focus {
    outline: 0;
    box-shadow: 0 0 0 0.02rem rgba(99, 97, 157, 0.5); }

.badge-light-red {
  color: #212529;
  background-color: #F6999A; }
  a.badge-light-red:hover, a.badge-light-red:focus {
    color: #212529;
    background-color: #f26a6c; }
  a.badge-light-red:focus, a.badge-light-red.focus {
    outline: 0;
    box-shadow: 0 0 0 0.02rem rgba(246, 153, 154, 0.5); }

.badge-light-green {
  color: #212529;
  background-color: #93EFA9; }
  a.badge-light-green:hover, a.badge-light-green:focus {
    color: #212529;
    background-color: #67e886; }
  a.badge-light-green:focus, a.badge-light-green.focus {
    outline: 0;
    box-shadow: 0 0 0 0.02rem rgba(147, 239, 169, 0.5); }

.badge-light-orange {
  color: #212529;
  background-color: #F9E6C5; }
  a.badge-light-orange:hover, a.badge-light-orange:focus {
    color: #212529;
    background-color: #f4d297; }
  a.badge-light-orange:focus, a.badge-light-orange.focus {
    outline: 0;
    box-shadow: 0 0 0 0.02rem rgba(249, 230, 197, 0.5); }

.badge-light-blue {
  color: #212529;
  background-color: #56CCF2; }
  a.badge-light-blue:hover, a.badge-light-blue:focus {
    color: #212529;
    background-color: #27beee; }
  a.badge-light-blue:focus, a.badge-light-blue.focus {
    outline: 0;
    box-shadow: 0 0 0 0.02rem rgba(86, 204, 242, 0.5); }

.badge-light-purple {
  color: #212529;
  background-color: #E0DFFF; }
  a.badge-light-purple:hover, a.badge-light-purple:focus {
    color: #212529;
    background-color: #afacff; }
  a.badge-light-purple:focus, a.badge-light-purple.focus {
    outline: 0;
    box-shadow: 0 0 0 0.02rem rgba(224, 223, 255, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.45rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.95rem 0.95rem;
  margin-bottom: 1rem;
  border: 0 solid transparent;
  border-radius: 0.3rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: bold; }

.alert-dismissible {
  padding-right: 3.2125rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.95rem 0.95rem;
    color: inherit; }

.alert-primary {
  color: #174077;
  background-color: #2c7be5;
  border-color: #c4daf8; }
  .alert-primary hr {
    border-top-color: #adccf5; }
  .alert-primary .alert-link {
    color: #0f294c; }

.alert-secondary {
  color: #524070;
  background-color: #9d7bd8;
  border-color: #e4daf4; }
  .alert-secondary hr {
    border-top-color: #d6c6ee; }
  .alert-secondary .alert-link {
    color: #3a2d50; }

.alert-success {
  color: #285b2a;
  background-color: #4caf50;
  border-color: #cde9ce; }
  .alert-success hr {
    border-top-color: #bbe1bd; }
  .alert-success .alert-link {
    color: #18381a; }

.alert-info {
  color: #256164;
  background-color: #47bac1;
  border-color: #cbecee; }
  .alert-info hr {
    border-top-color: #b8e5e8; }
  .alert-info .alert-link {
    color: #173d3f; }

.alert-warning {
  color: #854f00;
  background-color: #ff9800;
  border-color: #ffe2b8; }
  .alert-warning hr {
    border-top-color: #ffd89f; }
  .alert-warning .alert-link {
    color: #523100; }

.alert-danger {
  color: #770f12;
  background-color: #e51c23;
  border-color: #f8bfc1; }
  .alert-danger hr {
    border-top-color: #f5a8ab; }
  .alert-danger .alert-link {
    color: #4a090b; }

.alert-light {
  color: #818182;
  background-color: #f8f9fa;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1c212b;
  background-color: #354052;
  border-color: #c6cacf; }
  .alert-dark hr {
    border-top-color: #b8bdc3; }
  .alert-dark .alert-link {
    color: #08090c; }

.alert-tertiary {
  color: #2e4f7a;
  background-color: #5997eb;
  border-color: #d1e2f9; }
  .alert-tertiary hr {
    border-top-color: #bad4f6; }
  .alert-tertiary .alert-link {
    color: #203755; }

.alert-gray {
  color: #383d41;
  background-color: #6c757d;
  border-color: #d6d8db; }
  .alert-gray hr {
    border-top-color: #c8cbcf; }
  .alert-gray .alert-link {
    color: #202326; }

.alert-gray2 {
  color: #737678;
  background-color: #dee2e6;
  border-color: #f6f7f8; }
  .alert-gray2 hr {
    border-top-color: #e8eaed; }
  .alert-gray2 .alert-link {
    color: #5a5c5e; }

.alert-dark-red {
  color: #7a2d2d;
  background-color: #eb5757;
  border-color: #f9d0d0; }
  .alert-dark-red hr {
    border-top-color: #f6b9b9; }
  .alert-dark-red .alert-link {
    color: #551f1f; }

.alert-dark-green {
  color: #536858;
  background-color: #a0c8a9;
  border-color: #e4f0e7; }
  .alert-dark-green hr {
    border-top-color: #d4e7d8; }
  .alert-dark-green .alert-link {
    color: #3c4c40; }

.alert-dark-orange {
  color: #7c5204;
  background-color: #ee9e07;
  border-color: #fae4ba; }
  .alert-dark-orange hr {
    border-top-color: #f8dba2; }
  .alert-dark-orange .alert-link {
    color: #4b3102; }

.alert-dark-blue {
  color: #175172;
  background-color: #2d9cdb;
  border-color: #c4e3f5; }
  .alert-dark-blue hr {
    border-top-color: #aed9f1; }
  .alert-dark-blue .alert-link {
    color: #0e3348; }

.alert-dark-purple {
  color: #333252;
  background-color: #63619d;
  border-color: #d3d3e4; }
  .alert-dark-purple hr {
    border-top-color: #c3c3da; }
  .alert-dark-purple .alert-link {
    color: #1f1f32; }

.alert-light-red {
  color: #805050;
  background-color: #f6999a;
  border-color: #fce2e3; }
  .alert-light-red hr {
    border-top-color: #facbcd; }
  .alert-light-red .alert-link {
    color: #613c3c; }

.alert-light-green {
  color: #4c7c58;
  background-color: #93efa9;
  border-color: #e1fbe7; }
  .alert-light-green hr {
    border-top-color: #cbf8d5; }
  .alert-light-green .alert-link {
    color: #395c42; }

.alert-light-orange {
  color: #817866;
  background-color: #f9e6c5;
  border-color: #fdf8ef; }
  .alert-light-orange hr {
    border-top-color: #faeed8; }
  .alert-light-orange .alert-link {
    color: #655e4f; }

.alert-light-blue {
  color: #2d6a7e;
  background-color: #56ccf2;
  border-color: #d0f1fb; }
  .alert-light-blue hr {
    border-top-color: #b9eaf9; }
  .alert-light-blue .alert-link {
    color: #204a58; }

.alert-light-purple {
  color: #747485;
  background-color: #e0dfff;
  border-color: #f6f6ff; }
  .alert-light-purple hr {
    border-top-color: #ddddff; }
  .alert-light-purple .alert-link {
    color: #5c5c6a; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.65625rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #2c7be5;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #495057;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #2c7be5;
    border-color: #2c7be5; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0; }
    .list-group-horizontal .list-group-item:first-child {
      border-top-left-radius: 0.3rem;
      border-bottom-left-radius: 0.3rem;
      border-top-right-radius: 0; }
    .list-group-horizontal .list-group-item:last-child {
      margin-right: 0;
      border-top-right-radius: 0.3rem;
      border-bottom-right-radius: 0.3rem;
      border-bottom-left-radius: 0; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-sm .list-group-item:first-child {
        border-top-left-radius: 0.3rem;
        border-bottom-left-radius: 0.3rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.3rem;
        border-bottom-right-radius: 0.3rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-md .list-group-item:first-child {
        border-top-left-radius: 0.3rem;
        border-bottom-left-radius: 0.3rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.3rem;
        border-bottom-right-radius: 0.3rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-lg .list-group-item:first-child {
        border-top-left-radius: 0.3rem;
        border-bottom-left-radius: 0.3rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.3rem;
        border-bottom-right-radius: 0.3rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xl .list-group-item:first-child {
        border-top-left-radius: 0.3rem;
        border-bottom-left-radius: 0.3rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.3rem;
        border-bottom-right-radius: 0.3rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 1440px) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xxl .list-group-item:first-child {
        border-top-left-radius: 0.3rem;
        border-bottom-left-radius: 0.3rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-xxl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.3rem;
        border-bottom-right-radius: 0.3rem;
        border-bottom-left-radius: 0; } }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-primary {
  color: #174077;
  background-color: #c4daf8; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #174077;
    background-color: #adccf5; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #174077;
    border-color: #174077; }

.list-group-item-secondary {
  color: #524070;
  background-color: #e4daf4; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #524070;
    background-color: #d6c6ee; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #524070;
    border-color: #524070; }

.list-group-item-success {
  color: #285b2a;
  background-color: #cde9ce; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #285b2a;
    background-color: #bbe1bd; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #285b2a;
    border-color: #285b2a; }

.list-group-item-info {
  color: #256164;
  background-color: #cbecee; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #256164;
    background-color: #b8e5e8; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #256164;
    border-color: #256164; }

.list-group-item-warning {
  color: #854f00;
  background-color: #ffe2b8; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #854f00;
    background-color: #ffd89f; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #854f00;
    border-color: #854f00; }

.list-group-item-danger {
  color: #770f12;
  background-color: #f8bfc1; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #770f12;
    background-color: #f5a8ab; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #770f12;
    border-color: #770f12; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1c212b;
  background-color: #c6cacf; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1c212b;
    background-color: #b8bdc3; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1c212b;
    border-color: #1c212b; }

.list-group-item-tertiary {
  color: #2e4f7a;
  background-color: #d1e2f9; }
  .list-group-item-tertiary.list-group-item-action:hover, .list-group-item-tertiary.list-group-item-action:focus {
    color: #2e4f7a;
    background-color: #bad4f6; }
  .list-group-item-tertiary.list-group-item-action.active {
    color: #fff;
    background-color: #2e4f7a;
    border-color: #2e4f7a; }

.list-group-item-gray {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-gray.list-group-item-action:hover, .list-group-item-gray.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-gray.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-gray2 {
  color: #737678;
  background-color: #f6f7f8; }
  .list-group-item-gray2.list-group-item-action:hover, .list-group-item-gray2.list-group-item-action:focus {
    color: #737678;
    background-color: #e8eaed; }
  .list-group-item-gray2.list-group-item-action.active {
    color: #fff;
    background-color: #737678;
    border-color: #737678; }

.list-group-item-dark-red {
  color: #7a2d2d;
  background-color: #f9d0d0; }
  .list-group-item-dark-red.list-group-item-action:hover, .list-group-item-dark-red.list-group-item-action:focus {
    color: #7a2d2d;
    background-color: #f6b9b9; }
  .list-group-item-dark-red.list-group-item-action.active {
    color: #fff;
    background-color: #7a2d2d;
    border-color: #7a2d2d; }

.list-group-item-dark-green {
  color: #536858;
  background-color: #e4f0e7; }
  .list-group-item-dark-green.list-group-item-action:hover, .list-group-item-dark-green.list-group-item-action:focus {
    color: #536858;
    background-color: #d4e7d8; }
  .list-group-item-dark-green.list-group-item-action.active {
    color: #fff;
    background-color: #536858;
    border-color: #536858; }

.list-group-item-dark-orange {
  color: #7c5204;
  background-color: #fae4ba; }
  .list-group-item-dark-orange.list-group-item-action:hover, .list-group-item-dark-orange.list-group-item-action:focus {
    color: #7c5204;
    background-color: #f8dba2; }
  .list-group-item-dark-orange.list-group-item-action.active {
    color: #fff;
    background-color: #7c5204;
    border-color: #7c5204; }

.list-group-item-dark-blue {
  color: #175172;
  background-color: #c4e3f5; }
  .list-group-item-dark-blue.list-group-item-action:hover, .list-group-item-dark-blue.list-group-item-action:focus {
    color: #175172;
    background-color: #aed9f1; }
  .list-group-item-dark-blue.list-group-item-action.active {
    color: #fff;
    background-color: #175172;
    border-color: #175172; }

.list-group-item-dark-purple {
  color: #333252;
  background-color: #d3d3e4; }
  .list-group-item-dark-purple.list-group-item-action:hover, .list-group-item-dark-purple.list-group-item-action:focus {
    color: #333252;
    background-color: #c3c3da; }
  .list-group-item-dark-purple.list-group-item-action.active {
    color: #fff;
    background-color: #333252;
    border-color: #333252; }

.list-group-item-light-red {
  color: #805050;
  background-color: #fce2e3; }
  .list-group-item-light-red.list-group-item-action:hover, .list-group-item-light-red.list-group-item-action:focus {
    color: #805050;
    background-color: #facbcd; }
  .list-group-item-light-red.list-group-item-action.active {
    color: #fff;
    background-color: #805050;
    border-color: #805050; }

.list-group-item-light-green {
  color: #4c7c58;
  background-color: #e1fbe7; }
  .list-group-item-light-green.list-group-item-action:hover, .list-group-item-light-green.list-group-item-action:focus {
    color: #4c7c58;
    background-color: #cbf8d5; }
  .list-group-item-light-green.list-group-item-action.active {
    color: #fff;
    background-color: #4c7c58;
    border-color: #4c7c58; }

.list-group-item-light-orange {
  color: #817866;
  background-color: #fdf8ef; }
  .list-group-item-light-orange.list-group-item-action:hover, .list-group-item-light-orange.list-group-item-action:focus {
    color: #817866;
    background-color: #faeed8; }
  .list-group-item-light-orange.list-group-item-action.active {
    color: #fff;
    background-color: #817866;
    border-color: #817866; }

.list-group-item-light-blue {
  color: #2d6a7e;
  background-color: #d0f1fb; }
  .list-group-item-light-blue.list-group-item-action:hover, .list-group-item-light-blue.list-group-item-action:focus {
    color: #2d6a7e;
    background-color: #b9eaf9; }
  .list-group-item-light-blue.list-group-item-action.active {
    color: #fff;
    background-color: #2d6a7e;
    border-color: #2d6a7e; }

.list-group-item-light-purple {
  color: #747485;
  background-color: #f6f6ff; }
  .list-group-item-light-purple.list-group-item-action:hover, .list-group-item-light-purple.list-group-item-action:focus {
    color: #747485;
    background-color: #ddddff; }
  .list-group-item-light-purple.list-group-item-action.active {
    color: #fff;
    background-color: #747485;
    border-color: #747485; }

.close {
  float: right;
  font-size: 1.3125rem;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.25s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.2);
  border-radius: 0.45rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.45rem;
  border-top-right-radius: 0.45rem; }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.45rem;
  border-bottom-left-radius: 0.45rem; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 600px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 400px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 900px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "apercu-extralight";
  font-style: normal;
  font-weight: 300;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.75rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.3rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "apercu-extralight";
  font-style: normal;
  font-weight: 300;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.75rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.45rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.45rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.45rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc((0.5rem + 1px) * -1); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.45rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #000;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.45rem - 1px);
  border-top-right-radius: calc(0.45rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #495057; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.1em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #2c7be5 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #1862c6 !important; }

.bg-secondary {
  background-color: #9D7BD8 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #8054cc !important; }

.bg-success {
  background-color: #4CAF50 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #3d8b40 !important; }

.bg-info {
  background-color: #47BAC1 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #36999f !important; }

.bg-warning {
  background-color: #ff9800 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #cc7a00 !important; }

.bg-danger {
  background-color: #e51c23 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #b9151b !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #354052 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #212833 !important; }

.bg-tertiary {
  background-color: #5997eb !important; }

a.bg-tertiary:hover, a.bg-tertiary:focus,
button.bg-tertiary:hover,
button.bg-tertiary:focus {
  background-color: #2c7be5 !important; }

.bg-gray {
  background-color: #6c757d !important; }

a.bg-gray:hover, a.bg-gray:focus,
button.bg-gray:hover,
button.bg-gray:focus {
  background-color: #545b62 !important; }

.bg-gray2 {
  background-color: #dee2e6 !important; }

a.bg-gray2:hover, a.bg-gray2:focus,
button.bg-gray2:hover,
button.bg-gray2:focus {
  background-color: #c1c9d0 !important; }

.bg-dark-red {
  background-color: #EB5757 !important; }

a.bg-dark-red:hover, a.bg-dark-red:focus,
button.bg-dark-red:hover,
button.bg-dark-red:focus {
  background-color: #e62929 !important; }

.bg-dark-green {
  background-color: #A0C8A9 !important; }

a.bg-dark-green:hover, a.bg-dark-green:focus,
button.bg-dark-green:hover,
button.bg-dark-green:focus {
  background-color: #80b58c !important; }

.bg-dark-orange {
  background-color: #EE9E07 !important; }

a.bg-dark-orange:hover, a.bg-dark-orange:focus,
button.bg-dark-orange:hover,
button.bg-dark-orange:focus {
  background-color: #bc7d06 !important; }

.bg-dark-blue {
  background-color: #2D9CDB !important; }

a.bg-dark-blue:hover, a.bg-dark-blue:focus,
button.bg-dark-blue:hover,
button.bg-dark-blue:focus {
  background-color: #1f7fb6 !important; }

.bg-dark-purple {
  background-color: #63619D !important; }

a.bg-dark-purple:hover, a.bg-dark-purple:focus,
button.bg-dark-purple:hover,
button.bg-dark-purple:focus {
  background-color: #4f4e7d !important; }

.bg-light-red {
  background-color: #F6999A !important; }

a.bg-light-red:hover, a.bg-light-red:focus,
button.bg-light-red:hover,
button.bg-light-red:focus {
  background-color: #f26a6c !important; }

.bg-light-green {
  background-color: #93EFA9 !important; }

a.bg-light-green:hover, a.bg-light-green:focus,
button.bg-light-green:hover,
button.bg-light-green:focus {
  background-color: #67e886 !important; }

.bg-light-orange {
  background-color: #F9E6C5 !important; }

a.bg-light-orange:hover, a.bg-light-orange:focus,
button.bg-light-orange:hover,
button.bg-light-orange:focus {
  background-color: #f4d297 !important; }

.bg-light-blue {
  background-color: #56CCF2 !important; }

a.bg-light-blue:hover, a.bg-light-blue:focus,
button.bg-light-blue:hover,
button.bg-light-blue:focus {
  background-color: #27beee !important; }

.bg-light-purple {
  background-color: #E0DFFF !important; }

a.bg-light-purple:hover, a.bg-light-purple:focus,
button.bg-light-purple:hover,
button.bg-light-purple:focus {
  background-color: #afacff !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #2c7be5 !important; }

.border-secondary {
  border-color: #9D7BD8 !important; }

.border-success {
  border-color: #4CAF50 !important; }

.border-info {
  border-color: #47BAC1 !important; }

.border-warning {
  border-color: #ff9800 !important; }

.border-danger {
  border-color: #e51c23 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #354052 !important; }

.border-tertiary {
  border-color: #5997eb !important; }

.border-gray {
  border-color: #6c757d !important; }

.border-gray2 {
  border-color: #dee2e6 !important; }

.border-dark-red {
  border-color: #EB5757 !important; }

.border-dark-green {
  border-color: #A0C8A9 !important; }

.border-dark-orange {
  border-color: #EE9E07 !important; }

.border-dark-blue {
  border-color: #2D9CDB !important; }

.border-dark-purple {
  border-color: #63619D !important; }

.border-light-red {
  border-color: #F6999A !important; }

.border-light-green {
  border-color: #93EFA9 !important; }

.border-light-orange {
  border-color: #F9E6C5 !important; }

.border-light-blue {
  border-color: #56CCF2 !important; }

.border-light-purple {
  border-color: #E0DFFF !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.15rem !important; }

.rounded {
  border-radius: 0.3rem !important; }

.rounded-top {
  border-top-left-radius: 0.3rem !important;
  border-top-right-radius: 0.3rem !important; }

.rounded-right {
  border-top-right-radius: 0.3rem !important;
  border-bottom-right-radius: 0.3rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.3rem !important;
  border-bottom-left-radius: 0.3rem !important; }

.rounded-left {
  border-top-left-radius: 0.3rem !important;
  border-bottom-left-radius: 0.3rem !important; }

.rounded-lg {
  border-radius: 0.45rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1440px) {
  .d-xxl-none {
    display: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

@media (min-width: 1440px) {
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

@media (min-width: 1440px) {
  .float-xxl-left {
    float: left !important; }
  .float-xxl-right {
    float: right !important; }
  .float-xxl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.05rem 0.2rem rgba(0, 0, 0, 0.05) !important; }

.shadow {
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.05) !important; }

.shadow-lg {
  box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.05) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.m-6 {
  margin: 4.5rem !important; }

.mt-6,
.my-6 {
  margin-top: 4.5rem !important; }

.mr-6,
.mx-6 {
  margin-right: 4.5rem !important; }

.mb-6,
.my-6 {
  margin-bottom: 4.5rem !important; }

.ml-6,
.mx-6 {
  margin-left: 4.5rem !important; }

.m-7 {
  margin: 6rem !important; }

.mt-7,
.my-7 {
  margin-top: 6rem !important; }

.mr-7,
.mx-7 {
  margin-right: 6rem !important; }

.mb-7,
.my-7 {
  margin-bottom: 6rem !important; }

.ml-7,
.mx-7 {
  margin-left: 6rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.p-6 {
  padding: 4.5rem !important; }

.pt-6,
.py-6 {
  padding-top: 4.5rem !important; }

.pr-6,
.px-6 {
  padding-right: 4.5rem !important; }

.pb-6,
.py-6 {
  padding-bottom: 4.5rem !important; }

.pl-6,
.px-6 {
  padding-left: 4.5rem !important; }

.p-7 {
  padding: 6rem !important; }

.pt-7,
.py-7 {
  padding-top: 6rem !important; }

.pr-7,
.px-7 {
  padding-right: 6rem !important; }

.pb-7,
.py-7 {
  padding-bottom: 6rem !important; }

.pl-7,
.px-7 {
  padding-left: 6rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-n6 {
  margin: -4.5rem !important; }

.mt-n6,
.my-n6 {
  margin-top: -4.5rem !important; }

.mr-n6,
.mx-n6 {
  margin-right: -4.5rem !important; }

.mb-n6,
.my-n6 {
  margin-bottom: -4.5rem !important; }

.ml-n6,
.mx-n6 {
  margin-left: -4.5rem !important; }

.m-n7 {
  margin: -6rem !important; }

.mt-n7,
.my-n7 {
  margin-top: -6rem !important; }

.mr-n7,
.mx-n7 {
  margin-right: -6rem !important; }

.mb-n7,
.my-n7 {
  margin-bottom: -6rem !important; }

.ml-n7,
.mx-n7 {
  margin-left: -6rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .m-sm-6 {
    margin: 4.5rem !important; }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 4.5rem !important; }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 4.5rem !important; }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 4.5rem !important; }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 4.5rem !important; }
  .m-sm-7 {
    margin: 6rem !important; }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 6rem !important; }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 6rem !important; }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 6rem !important; }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 6rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .p-sm-6 {
    padding: 4.5rem !important; }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 4.5rem !important; }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 4.5rem !important; }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 4.5rem !important; }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 4.5rem !important; }
  .p-sm-7 {
    padding: 6rem !important; }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 6rem !important; }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 6rem !important; }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 6rem !important; }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 6rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-n6 {
    margin: -4.5rem !important; }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -4.5rem !important; }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -4.5rem !important; }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -4.5rem !important; }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -4.5rem !important; }
  .m-sm-n7 {
    margin: -6rem !important; }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -6rem !important; }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -6rem !important; }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -6rem !important; }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -6rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .m-md-6 {
    margin: 4.5rem !important; }
  .mt-md-6,
  .my-md-6 {
    margin-top: 4.5rem !important; }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 4.5rem !important; }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 4.5rem !important; }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 4.5rem !important; }
  .m-md-7 {
    margin: 6rem !important; }
  .mt-md-7,
  .my-md-7 {
    margin-top: 6rem !important; }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 6rem !important; }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 6rem !important; }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 6rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .p-md-6 {
    padding: 4.5rem !important; }
  .pt-md-6,
  .py-md-6 {
    padding-top: 4.5rem !important; }
  .pr-md-6,
  .px-md-6 {
    padding-right: 4.5rem !important; }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 4.5rem !important; }
  .pl-md-6,
  .px-md-6 {
    padding-left: 4.5rem !important; }
  .p-md-7 {
    padding: 6rem !important; }
  .pt-md-7,
  .py-md-7 {
    padding-top: 6rem !important; }
  .pr-md-7,
  .px-md-7 {
    padding-right: 6rem !important; }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 6rem !important; }
  .pl-md-7,
  .px-md-7 {
    padding-left: 6rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-n6 {
    margin: -4.5rem !important; }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -4.5rem !important; }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -4.5rem !important; }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -4.5rem !important; }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -4.5rem !important; }
  .m-md-n7 {
    margin: -6rem !important; }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -6rem !important; }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -6rem !important; }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -6rem !important; }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -6rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .m-lg-6 {
    margin: 4.5rem !important; }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 4.5rem !important; }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 4.5rem !important; }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 4.5rem !important; }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 4.5rem !important; }
  .m-lg-7 {
    margin: 6rem !important; }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 6rem !important; }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 6rem !important; }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 6rem !important; }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 6rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .p-lg-6 {
    padding: 4.5rem !important; }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 4.5rem !important; }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 4.5rem !important; }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 4.5rem !important; }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 4.5rem !important; }
  .p-lg-7 {
    padding: 6rem !important; }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 6rem !important; }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 6rem !important; }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 6rem !important; }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 6rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-n6 {
    margin: -4.5rem !important; }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -4.5rem !important; }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -4.5rem !important; }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -4.5rem !important; }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -4.5rem !important; }
  .m-lg-n7 {
    margin: -6rem !important; }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -6rem !important; }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -6rem !important; }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -6rem !important; }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -6rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .m-xl-6 {
    margin: 4.5rem !important; }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 4.5rem !important; }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 4.5rem !important; }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 4.5rem !important; }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 4.5rem !important; }
  .m-xl-7 {
    margin: 6rem !important; }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 6rem !important; }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 6rem !important; }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 6rem !important; }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 6rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .p-xl-6 {
    padding: 4.5rem !important; }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 4.5rem !important; }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 4.5rem !important; }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 4.5rem !important; }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 4.5rem !important; }
  .p-xl-7 {
    padding: 6rem !important; }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 6rem !important; }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 6rem !important; }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 6rem !important; }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 6rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-n6 {
    margin: -4.5rem !important; }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -4.5rem !important; }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -4.5rem !important; }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -4.5rem !important; }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -4.5rem !important; }
  .m-xl-n7 {
    margin: -6rem !important; }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -6rem !important; }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -6rem !important; }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -6rem !important; }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -6rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

@media (min-width: 1440px) {
  .m-xxl-0 {
    margin: 0 !important; }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important; }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important; }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important; }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important; }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important; }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important; }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important; }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important; }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important; }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important; }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important; }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important; }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important; }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important; }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important; }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important; }
  .m-xxl-6 {
    margin: 4.5rem !important; }
  .mt-xxl-6,
  .my-xxl-6 {
    margin-top: 4.5rem !important; }
  .mr-xxl-6,
  .mx-xxl-6 {
    margin-right: 4.5rem !important; }
  .mb-xxl-6,
  .my-xxl-6 {
    margin-bottom: 4.5rem !important; }
  .ml-xxl-6,
  .mx-xxl-6 {
    margin-left: 4.5rem !important; }
  .m-xxl-7 {
    margin: 6rem !important; }
  .mt-xxl-7,
  .my-xxl-7 {
    margin-top: 6rem !important; }
  .mr-xxl-7,
  .mx-xxl-7 {
    margin-right: 6rem !important; }
  .mb-xxl-7,
  .my-xxl-7 {
    margin-bottom: 6rem !important; }
  .ml-xxl-7,
  .mx-xxl-7 {
    margin-left: 6rem !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important; }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important; }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important; }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important; }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important; }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important; }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important; }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important; }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important; }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important; }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important; }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important; }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important; }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important; }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important; }
  .p-xxl-6 {
    padding: 4.5rem !important; }
  .pt-xxl-6,
  .py-xxl-6 {
    padding-top: 4.5rem !important; }
  .pr-xxl-6,
  .px-xxl-6 {
    padding-right: 4.5rem !important; }
  .pb-xxl-6,
  .py-xxl-6 {
    padding-bottom: 4.5rem !important; }
  .pl-xxl-6,
  .px-xxl-6 {
    padding-left: 4.5rem !important; }
  .p-xxl-7 {
    padding: 6rem !important; }
  .pt-xxl-7,
  .py-xxl-7 {
    padding-top: 6rem !important; }
  .pr-xxl-7,
  .px-xxl-7 {
    padding-right: 6rem !important; }
  .pb-xxl-7,
  .py-xxl-7 {
    padding-bottom: 6rem !important; }
  .pl-xxl-7,
  .px-xxl-7 {
    padding-left: 6rem !important; }
  .m-xxl-n1 {
    margin: -0.25rem !important; }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.25rem !important; }
  .m-xxl-n2 {
    margin: -0.5rem !important; }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.5rem !important; }
  .m-xxl-n3 {
    margin: -1rem !important; }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1rem !important; }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1rem !important; }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1rem !important; }
  .m-xxl-n4 {
    margin: -1.5rem !important; }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -1.5rem !important; }
  .m-xxl-n5 {
    margin: -3rem !important; }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -3rem !important; }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -3rem !important; }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -3rem !important; }
  .m-xxl-n6 {
    margin: -4.5rem !important; }
  .mt-xxl-n6,
  .my-xxl-n6 {
    margin-top: -4.5rem !important; }
  .mr-xxl-n6,
  .mx-xxl-n6 {
    margin-right: -4.5rem !important; }
  .mb-xxl-n6,
  .my-xxl-n6 {
    margin-bottom: -4.5rem !important; }
  .ml-xxl-n6,
  .mx-xxl-n6 {
    margin-left: -4.5rem !important; }
  .m-xxl-n7 {
    margin: -6rem !important; }
  .mt-xxl-n7,
  .my-xxl-n7 {
    margin-top: -6rem !important; }
  .mr-xxl-n7,
  .mx-xxl-n7 {
    margin-right: -6rem !important; }
  .mb-xxl-n7,
  .my-xxl-n7 {
    margin-bottom: -6rem !important; }
  .ml-xxl-n7,
  .mx-xxl-n7 {
    margin-left: -6rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important; }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important; }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1440px) {
  .text-xxl-left {
    text-align: left !important; }
  .text-xxl-right {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: lighter !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 300 !important; }

.font-weight-bold {
  font-weight: bold !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #2c7be5 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #1657af !important; }

.text-secondary {
  color: #9D7BD8 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #7140c7 !important; }

.text-success {
  color: #4CAF50 !important; }

a.text-success:hover, a.text-success:focus {
  color: #357a38 !important; }

.text-info {
  color: #47BAC1 !important; }

a.text-info:hover, a.text-info:focus {
  color: #2f878c !important; }

.text-warning {
  color: #ff9800 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #b36a00 !important; }

.text-danger {
  color: #e51c23 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a21318 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #354052 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #171c24 !important; }

.text-tertiary {
  color: #5997eb !important; }

a.text-tertiary:hover, a.text-tertiary:focus {
  color: #1b6edc !important; }

.text-gray {
  color: #6c757d !important; }

a.text-gray:hover, a.text-gray:focus {
  color: #494f54 !important; }

.text-gray2 {
  color: #dee2e6 !important; }

a.text-gray2:hover, a.text-gray2:focus {
  color: #b2bcc5 !important; }

.text-dark-red {
  color: #EB5757 !important; }

a.text-dark-red:hover, a.text-dark-red:focus {
  color: #db1a1a !important; }

.text-dark-green {
  color: #A0C8A9 !important; }

a.text-dark-green:hover, a.text-dark-green:focus {
  color: #70ac7d !important; }

.text-dark-orange {
  color: #EE9E07 !important; }

a.text-dark-orange:hover, a.text-dark-orange:focus {
  color: #a46d05 !important; }

.text-dark-blue {
  color: #2D9CDB !important; }

a.text-dark-blue:hover, a.text-dark-blue:focus {
  color: #1b70a0 !important; }

.text-dark-purple {
  color: #63619D !important; }

a.text-dark-purple:hover, a.text-dark-purple:focus {
  color: #45446e !important; }

.text-light-red {
  color: #F6999A !important; }

a.text-light-red:hover, a.text-light-red:focus {
  color: #f05354 !important; }

.text-light-green {
  color: #93EFA9 !important; }

a.text-light-green:hover, a.text-light-green:focus {
  color: #50e574 !important; }

.text-light-orange {
  color: #F9E6C5 !important; }

a.text-light-orange:hover, a.text-light-orange:focus {
  color: #f2c880 !important; }

.text-light-blue {
  color: #56CCF2 !important; }

a.text-light-blue:hover, a.text-light-blue:focus {
  color: #12b5ea !important; }

.text-light-purple {
  color: #E0DFFF !important; }

a.text-light-purple:hover, a.text-light-purple:focus {
  color: #9693ff !important; }

.text-body {
  color: #495057 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

.btn-label-primary {
  color: #212529;
  background-color: #f4f8fe;
  border-color: #d9e7fa;
  border-width: 0;
  color: #1862c6; }
  .btn-label-primary:hover {
    color: #fff;
    background-color: #2c7be5;
    border-color: #abcbf5; }
  .btn-label-primary:focus, .btn-label-primary.focus {
    box-shadow: 0 0 0 0.02rem rgba(189, 202, 219, 0.5); }
  .btn-label-primary.disabled, .btn-label-primary:disabled {
    color: #212529;
    background-color: #f4f8fe;
    border-color: #d9e7fa; }
  .btn-label-primary:not(:disabled):not(.disabled):active, .btn-label-primary:not(:disabled):not(.disabled).active,
  .show > .btn-label-primary.dropdown-toggle {
    color: #212529;
    background-color: #c6dcf8;
    border-color: #a0c3f3; }
    .btn-label-primary:not(:disabled):not(.disabled):active:focus, .btn-label-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-label-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(189, 202, 219, 0.5); }
  .btn-label-primary.active-link {
    background-color: #5997eb;
    color: #fff; }

.btn-label-secondary {
  color: #212529;
  background-color: white;
  border-color: white;
  border-width: 0;
  color: #8054cc; }
  .btn-label-secondary:hover {
    color: #fff;
    background-color: #9D7BD8;
    border-color: #e6e6e6; }
  .btn-label-secondary:focus, .btn-label-secondary.focus {
    box-shadow: 0 0 0 0.02rem rgba(222, 222, 223, 0.5); }
  .btn-label-secondary.disabled, .btn-label-secondary:disabled {
    color: #212529;
    background-color: white;
    border-color: white; }
  .btn-label-secondary:not(:disabled):not(.disabled):active, .btn-label-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-label-secondary.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-label-secondary:not(:disabled):not(.disabled):active:focus, .btn-label-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-label-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(222, 222, 223, 0.5); }
  .btn-label-secondary.active-link {
    background-color: #baa2e4;
    color: #fff; }

.btn-label-success {
  color: #212529;
  background-color: #e7f5e7;
  border-color: #d2ebd3;
  border-width: 0;
  color: #3d8b40; }
  .btn-label-success:hover {
    color: #fff;
    background-color: #4CAF50;
    border-color: #aedcb0; }
  .btn-label-success:focus, .btn-label-success.focus {
    box-shadow: 0 0 0 0.02rem rgba(183, 206, 185, 0.5); }
  .btn-label-success.disabled, .btn-label-success:disabled {
    color: #212529;
    background-color: #e7f5e7;
    border-color: #d2ebd3; }
  .btn-label-success:not(:disabled):not(.disabled):active, .btn-label-success:not(:disabled):not(.disabled).active,
  .show > .btn-label-success.dropdown-toggle {
    color: #212529;
    background-color: #c3e5c5;
    border-color: #a5d8a7; }
    .btn-label-success:not(:disabled):not(.disabled):active:focus, .btn-label-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-label-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(183, 206, 185, 0.5); }
  .btn-label-success.active-link {
    background-color: #6ec071;
    color: #fff; }

.btn-label-info {
  color: #212529;
  background-color: #eff9fa;
  border-color: #d8f0f2;
  border-width: 0;
  color: #36999f; }
  .btn-label-info:hover {
    color: #212529;
    background-color: #47BAC1;
    border-color: #b2e2e5; }
  .btn-label-info:focus, .btn-label-info.focus {
    box-shadow: 0 0 0 0.02rem rgba(189, 210, 212, 0.5); }
  .btn-label-info.disabled, .btn-label-info:disabled {
    color: #212529;
    background-color: #eff9fa;
    border-color: #d8f0f2; }
  .btn-label-info:not(:disabled):not(.disabled):active, .btn-label-info:not(:disabled):not(.disabled).active,
  .show > .btn-label-info.dropdown-toggle {
    color: #212529;
    background-color: #c9ebed;
    border-color: #a8dee2; }
    .btn-label-info:not(:disabled):not(.disabled):active:focus, .btn-label-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-label-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(189, 210, 212, 0.5); }
  .btn-label-info.active-link {
    background-color: #6dc8ce;
    color: #212529; }

.btn-label-warning {
  color: #212529;
  background-color: #fff3e0;
  border-color: #ffe6c2;
  border-width: 0;
  color: #cc7a00; }
  .btn-label-warning:hover {
    color: #212529;
    background-color: #ff9800;
    border-color: #ffd28f; }
  .btn-label-warning:focus, .btn-label-warning.focus {
    box-shadow: 0 0 0 0.02rem rgba(222, 201, 171, 0.5); }
  .btn-label-warning.disabled, .btn-label-warning:disabled {
    color: #212529;
    background-color: #fff3e0;
    border-color: #ffe6c2; }
  .btn-label-warning:not(:disabled):not(.disabled):active, .btn-label-warning:not(:disabled):not(.disabled).active,
  .show > .btn-label-warning.dropdown-toggle {
    color: #212529;
    background-color: navajowhite;
    border-color: #ffcd82; }
    .btn-label-warning:not(:disabled):not(.disabled):active:focus, .btn-label-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-label-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(222, 201, 171, 0.5); }
  .btn-label-warning.active-link {
    background-color: #ffad33;
    color: #212529; }

.btn-label-danger {
  color: #212529;
  background-color: #fce5e6;
  border-color: #f9cacc;
  border-width: 0;
  color: #b9151b; }
  .btn-label-danger:hover {
    color: #fff;
    background-color: #e51c23;
    border-color: #f49c9f; }
  .btn-label-danger:focus, .btn-label-danger.focus {
    box-shadow: 0 0 0 0.02rem rgba(217, 177, 179, 0.5); }
  .btn-label-danger.disabled, .btn-label-danger:disabled {
    color: #212529;
    background-color: #fce5e6;
    border-color: #f9cacc; }
  .btn-label-danger:not(:disabled):not(.disabled):active, .btn-label-danger:not(:disabled):not(.disabled).active,
  .show > .btn-label-danger.dropdown-toggle {
    color: #212529;
    background-color: #f7b8ba;
    border-color: #f29194; }
    .btn-label-danger:not(:disabled):not(.disabled):active:focus, .btn-label-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-label-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(217, 177, 179, 0.5); }
  .btn-label-danger.active-link {
    background-color: #ea4a4f;
    color: #fff; }

.btn-label-light {
  color: #212529;
  background-color: white;
  border-color: white;
  border-width: 0;
  color: #dae0e5; }
  .btn-label-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #e6e6e6; }
  .btn-label-light:focus, .btn-label-light.focus {
    box-shadow: 0 0 0 0.02rem rgba(222, 222, 223, 0.5); }
  .btn-label-light.disabled, .btn-label-light:disabled {
    color: #212529;
    background-color: white;
    border-color: white; }
  .btn-label-light:not(:disabled):not(.disabled):active, .btn-label-light:not(:disabled):not(.disabled).active,
  .show > .btn-label-light.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-label-light:not(:disabled):not(.disabled):active:focus, .btn-label-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-label-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(222, 222, 223, 0.5); }
  .btn-label-light.active-link {
    background-color: white;
    color: #212529; }

.btn-label-dark {
  color: #212529;
  background-color: #a4b0c4;
  border-color: #91a0b8;
  border-width: 0;
  color: #212833; }
  .btn-label-dark:hover {
    color: #fff;
    background-color: #354052;
    border-color: #7285a4; }
  .btn-label-dark:focus, .btn-label-dark.focus {
    box-shadow: 0 0 0 0.02rem rgba(128, 141, 162, 0.5); }
  .btn-label-dark.disabled, .btn-label-dark:disabled {
    color: #212529;
    background-color: #a4b0c4;
    border-color: #91a0b8; }
  .btn-label-dark:not(:disabled):not(.disabled):active, .btn-label-dark:not(:disabled):not(.disabled).active,
  .show > .btn-label-dark.dropdown-toggle {
    color: #fff;
    background-color: #8595b0;
    border-color: #6a7e9f; }
    .btn-label-dark:not(:disabled):not(.disabled):active:focus, .btn-label-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-label-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(128, 141, 162, 0.5); }
  .btn-label-dark.active-link {
    background-color: #495871;
    color: #fff; }

.btn-label-tertiary {
  color: #212529;
  background-color: white;
  border-color: white;
  border-width: 0;
  color: #2c7be5; }
  .btn-label-tertiary:hover {
    color: #fff;
    background-color: #5997eb;
    border-color: #e6e6e6; }
  .btn-label-tertiary:focus, .btn-label-tertiary.focus {
    box-shadow: 0 0 0 0.02rem rgba(222, 222, 223, 0.5); }
  .btn-label-tertiary.disabled, .btn-label-tertiary:disabled {
    color: #212529;
    background-color: white;
    border-color: white; }
  .btn-label-tertiary:not(:disabled):not(.disabled):active, .btn-label-tertiary:not(:disabled):not(.disabled).active,
  .show > .btn-label-tertiary.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-label-tertiary:not(:disabled):not(.disabled):active:focus, .btn-label-tertiary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-label-tertiary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(222, 222, 223, 0.5); }
  .btn-label-tertiary.active-link {
    background-color: #87b4f0;
    color: #fff; }

.btn-label-gray {
  color: #212529;
  background-color: #e3e5e7;
  border-color: #d2d6d8;
  border-width: 0;
  color: #545b62; }
  .btn-label-gray:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #b7bcc1; }
  .btn-label-gray:focus, .btn-label-gray.focus {
    box-shadow: 0 0 0 0.02rem rgba(184, 187, 190, 0.5); }
  .btn-label-gray.disabled, .btn-label-gray:disabled {
    color: #212529;
    background-color: #e3e5e7;
    border-color: #d2d6d8; }
  .btn-label-gray:not(:disabled):not(.disabled):active, .btn-label-gray:not(:disabled):not(.disabled).active,
  .show > .btn-label-gray.dropdown-toggle {
    color: #212529;
    background-color: #c7cbcf;
    border-color: #b0b6bb; }
    .btn-label-gray:not(:disabled):not(.disabled):active:focus, .btn-label-gray:not(:disabled):not(.disabled).active:focus,
    .show > .btn-label-gray.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(184, 187, 190, 0.5); }
  .btn-label-gray.active-link {
    background-color: #868e96;
    color: #fff; }

.btn-label-gray2 {
  color: #212529;
  background-color: white;
  border-color: white;
  border-width: 0;
  color: #c1c9d0; }
  .btn-label-gray2:hover {
    color: #212529;
    background-color: #dee2e6;
    border-color: #e6e6e6; }
  .btn-label-gray2:focus, .btn-label-gray2.focus {
    box-shadow: 0 0 0 0.02rem rgba(222, 222, 223, 0.5); }
  .btn-label-gray2.disabled, .btn-label-gray2:disabled {
    color: #212529;
    background-color: white;
    border-color: white; }
  .btn-label-gray2:not(:disabled):not(.disabled):active, .btn-label-gray2:not(:disabled):not(.disabled).active,
  .show > .btn-label-gray2.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-label-gray2:not(:disabled):not(.disabled):active:focus, .btn-label-gray2:not(:disabled):not(.disabled).active:focus,
    .show > .btn-label-gray2.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(222, 222, 223, 0.5); }
  .btn-label-gray2.active-link {
    background-color: #fbfcfc;
    color: #212529; }

.btn-label-dark-red {
  color: #212529;
  background-color: white;
  border-color: white;
  border-width: 0;
  color: #e62929; }
  .btn-label-dark-red:hover {
    color: #fff;
    background-color: #EB5757;
    border-color: #e6e6e6; }
  .btn-label-dark-red:focus, .btn-label-dark-red.focus {
    box-shadow: 0 0 0 0.02rem rgba(222, 222, 223, 0.5); }
  .btn-label-dark-red.disabled, .btn-label-dark-red:disabled {
    color: #212529;
    background-color: white;
    border-color: white; }
  .btn-label-dark-red:not(:disabled):not(.disabled):active, .btn-label-dark-red:not(:disabled):not(.disabled).active,
  .show > .btn-label-dark-red.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-label-dark-red:not(:disabled):not(.disabled):active:focus, .btn-label-dark-red:not(:disabled):not(.disabled).active:focus,
    .show > .btn-label-dark-red.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(222, 222, 223, 0.5); }
  .btn-label-dark-red.active-link {
    background-color: #f08585;
    color: #fff; }

.btn-label-dark-green {
  color: #212529;
  background-color: white;
  border-color: white;
  border-width: 0;
  color: #80b58c; }
  .btn-label-dark-green:hover {
    color: #212529;
    background-color: #A0C8A9;
    border-color: #e6e6e6; }
  .btn-label-dark-green:focus, .btn-label-dark-green.focus {
    box-shadow: 0 0 0 0.02rem rgba(222, 222, 223, 0.5); }
  .btn-label-dark-green.disabled, .btn-label-dark-green:disabled {
    color: #212529;
    background-color: white;
    border-color: white; }
  .btn-label-dark-green:not(:disabled):not(.disabled):active, .btn-label-dark-green:not(:disabled):not(.disabled).active,
  .show > .btn-label-dark-green.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-label-dark-green:not(:disabled):not(.disabled):active:focus, .btn-label-dark-green:not(:disabled):not(.disabled).active:focus,
    .show > .btn-label-dark-green.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(222, 222, 223, 0.5); }
  .btn-label-dark-green.active-link {
    background-color: #c0dbc6;
    color: #212529; }

.btn-label-dark-orange {
  color: #212529;
  background-color: #fef1d8;
  border-color: #fde6ba;
  border-width: 0;
  color: #bc7d06; }
  .btn-label-dark-orange:hover {
    color: #212529;
    background-color: #EE9E07;
    border-color: #fcd488; }
  .btn-label-dark-orange:focus, .btn-label-dark-orange.focus {
    box-shadow: 0 0 0 0.02rem rgba(220, 201, 164, 0.5); }
  .btn-label-dark-orange.disabled, .btn-label-dark-orange:disabled {
    color: #212529;
    background-color: #fef1d8;
    border-color: #fde6ba; }
  .btn-label-dark-orange:not(:disabled):not(.disabled):active, .btn-label-dark-orange:not(:disabled):not(.disabled).active,
  .show > .btn-label-dark-orange.dropdown-toggle {
    color: #212529;
    background-color: #fcdea6;
    border-color: #fbcf7c; }
    .btn-label-dark-orange:not(:disabled):not(.disabled):active:focus, .btn-label-dark-orange:not(:disabled):not(.disabled).active:focus,
    .show > .btn-label-dark-orange.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(220, 201, 164, 0.5); }
  .btn-label-dark-orange.active-link {
    background-color: #f9b32f;
    color: #212529; }

.btn-label-dark-blue {
  color: #212529;
  background-color: #edf6fc;
  border-color: #d2eaf7;
  border-width: 0;
  color: #1f7fb6; }
  .btn-label-dark-blue:hover {
    color: #fff;
    background-color: #2D9CDB;
    border-color: #a7d5f0; }
  .btn-label-dark-blue:focus, .btn-label-dark-blue.focus {
    box-shadow: 0 0 0 0.02rem rgba(184, 204, 216, 0.5); }
  .btn-label-dark-blue.disabled, .btn-label-dark-blue:disabled {
    color: #212529;
    background-color: #edf6fc;
    border-color: #d2eaf7; }
  .btn-label-dark-blue:not(:disabled):not(.disabled):active, .btn-label-dark-blue:not(:disabled):not(.disabled).active,
  .show > .btn-label-dark-blue.dropdown-toggle {
    color: #212529;
    background-color: #c1e2f4;
    border-color: #9cd0ee; }
    .btn-label-dark-blue:not(:disabled):not(.disabled):active:focus, .btn-label-dark-blue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-label-dark-blue.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(184, 204, 216, 0.5); }
  .btn-label-dark-blue.active-link {
    background-color: #59b1e2;
    color: #fff; }

.btn-label-dark-purple {
  color: #212529;
  background-color: #ecebf3;
  border-color: #d9d9e7;
  border-width: 0;
  color: #4f4e7d; }
  .btn-label-dark-purple:hover {
    color: #fff;
    background-color: #63619D;
    border-color: #bab9d4; }
  .btn-label-dark-purple:focus, .btn-label-dark-purple.focus {
    box-shadow: 0 0 0 0.02rem rgba(189, 190, 203, 0.5); }
  .btn-label-dark-purple.disabled, .btn-label-dark-purple:disabled {
    color: #212529;
    background-color: #ecebf3;
    border-color: #d9d9e7; }
  .btn-label-dark-purple:not(:disabled):not(.disabled):active, .btn-label-dark-purple:not(:disabled):not(.disabled).active,
  .show > .btn-label-dark-purple.dropdown-toggle {
    color: #212529;
    background-color: #cdccdf;
    border-color: #b2b1cf; }
    .btn-label-dark-purple:not(:disabled):not(.disabled):active:focus, .btn-label-dark-purple:not(:disabled):not(.disabled).active:focus,
    .show > .btn-label-dark-purple.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(189, 190, 203, 0.5); }
  .btn-label-dark-purple.active-link {
    background-color: #8280b1;
    color: #fff; }

.btn-label-light-red {
  color: #212529;
  background-color: white;
  border-color: white;
  border-width: 0;
  color: #f26a6c; }
  .btn-label-light-red:hover {
    color: #212529;
    background-color: #F6999A;
    border-color: #e6e6e6; }
  .btn-label-light-red:focus, .btn-label-light-red.focus {
    box-shadow: 0 0 0 0.02rem rgba(222, 222, 223, 0.5); }
  .btn-label-light-red.disabled, .btn-label-light-red:disabled {
    color: #212529;
    background-color: white;
    border-color: white; }
  .btn-label-light-red:not(:disabled):not(.disabled):active, .btn-label-light-red:not(:disabled):not(.disabled).active,
  .show > .btn-label-light-red.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-label-light-red:not(:disabled):not(.disabled):active:focus, .btn-label-light-red:not(:disabled):not(.disabled).active:focus,
    .show > .btn-label-light-red.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(222, 222, 223, 0.5); }
  .btn-label-light-red.active-link {
    background-color: #fac8c8;
    color: #212529; }

.btn-label-light-green {
  color: #212529;
  background-color: white;
  border-color: white;
  border-width: 0;
  color: #67e886; }
  .btn-label-light-green:hover {
    color: #212529;
    background-color: #93EFA9;
    border-color: #e6e6e6; }
  .btn-label-light-green:focus, .btn-label-light-green.focus {
    box-shadow: 0 0 0 0.02rem rgba(222, 222, 223, 0.5); }
  .btn-label-light-green.disabled, .btn-label-light-green:disabled {
    color: #212529;
    background-color: white;
    border-color: white; }
  .btn-label-light-green:not(:disabled):not(.disabled):active, .btn-label-light-green:not(:disabled):not(.disabled).active,
  .show > .btn-label-light-green.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-label-light-green:not(:disabled):not(.disabled):active:focus, .btn-label-light-green:not(:disabled):not(.disabled).active:focus,
    .show > .btn-label-light-green.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(222, 222, 223, 0.5); }
  .btn-label-light-green.active-link {
    background-color: #bff6cc;
    color: #212529; }

.btn-label-light-orange {
  color: #212529;
  background-color: white;
  border-color: white;
  border-width: 0;
  color: #f4d297; }
  .btn-label-light-orange:hover {
    color: #212529;
    background-color: #F9E6C5;
    border-color: #e6e6e6; }
  .btn-label-light-orange:focus, .btn-label-light-orange.focus {
    box-shadow: 0 0 0 0.02rem rgba(222, 222, 223, 0.5); }
  .btn-label-light-orange.disabled, .btn-label-light-orange:disabled {
    color: #212529;
    background-color: white;
    border-color: white; }
  .btn-label-light-orange:not(:disabled):not(.disabled):active, .btn-label-light-orange:not(:disabled):not(.disabled).active,
  .show > .btn-label-light-orange.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-label-light-orange:not(:disabled):not(.disabled):active:focus, .btn-label-light-orange:not(:disabled):not(.disabled).active:focus,
    .show > .btn-label-light-orange.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(222, 222, 223, 0.5); }
  .btn-label-light-orange.active-link {
    background-color: #fefaf3;
    color: #212529; }

.btn-label-light-blue {
  color: #212529;
  background-color: white;
  border-color: white;
  border-width: 0;
  color: #27beee; }
  .btn-label-light-blue:hover {
    color: #212529;
    background-color: #56CCF2;
    border-color: #e6e6e6; }
  .btn-label-light-blue:focus, .btn-label-light-blue.focus {
    box-shadow: 0 0 0 0.02rem rgba(222, 222, 223, 0.5); }
  .btn-label-light-blue.disabled, .btn-label-light-blue:disabled {
    color: #212529;
    background-color: white;
    border-color: white; }
  .btn-label-light-blue:not(:disabled):not(.disabled):active, .btn-label-light-blue:not(:disabled):not(.disabled).active,
  .show > .btn-label-light-blue.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-label-light-blue:not(:disabled):not(.disabled):active:focus, .btn-label-light-blue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-label-light-blue.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(222, 222, 223, 0.5); }
  .btn-label-light-blue.active-link {
    background-color: #85daf6;
    color: #212529; }

.btn-label-light-purple {
  color: #212529;
  background-color: white;
  border-color: white;
  border-width: 0;
  color: #afacff; }
  .btn-label-light-purple:hover {
    color: #212529;
    background-color: #E0DFFF;
    border-color: #e6e6e6; }
  .btn-label-light-purple:focus, .btn-label-light-purple.focus {
    box-shadow: 0 0 0 0.02rem rgba(222, 222, 223, 0.5); }
  .btn-label-light-purple.disabled, .btn-label-light-purple:disabled {
    color: #212529;
    background-color: white;
    border-color: white; }
  .btn-label-light-purple:not(:disabled):not(.disabled):active, .btn-label-light-purple:not(:disabled):not(.disabled).active,
  .show > .btn-label-light-purple.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-label-light-purple:not(:disabled):not(.disabled):active:focus, .btn-label-light-purple:not(:disabled):not(.disabled).active:focus,
    .show > .btn-label-light-purple.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.02rem rgba(222, 222, 223, 0.5); }
  .btn-label-light-purple.active-link {
    background-color: white;
    color: #212529; }

.alert {
  padding: 0;
  display: flex;
  color: #fff; }
  .alert .close:hover,
  .alert .close:focus {
    color: #fff;
    opacity: 1; }

.alert-outline,
.alert-outline-coloured {
  color: #495057;
  background: #fff; }
  .alert-outline hr,
  .alert-outline-coloured hr {
    border-top-color: #ced4da; }
  .alert-outline .close:hover,
  .alert-outline .close:focus,
  .alert-outline-coloured .close:hover,
  .alert-outline-coloured .close:focus {
    color: #343a40; }
  .alert-outline .alert-message,
  .alert-outline-coloured .alert-message {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
    border: 1px solid #ced4da; }
  .alert-outline .alert-message:not(:nth-child(2)),
  .alert-outline-coloured .alert-message:not(:nth-child(2)) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0; }
  .alert-outline .alert-icon,
  .alert-outline-coloured .alert-icon {
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
    color: #fff; }
  .alert-outline.alert-primary .alert-icon,
  .alert-outline-coloured.alert-primary .alert-icon {
    background-color: #2c7be5; }
  .alert-outline.alert-secondary .alert-icon,
  .alert-outline-coloured.alert-secondary .alert-icon {
    background-color: #9D7BD8; }
  .alert-outline.alert-success .alert-icon,
  .alert-outline-coloured.alert-success .alert-icon {
    background-color: #4CAF50; }
  .alert-outline.alert-info .alert-icon,
  .alert-outline-coloured.alert-info .alert-icon {
    background-color: #47BAC1; }
  .alert-outline.alert-warning .alert-icon,
  .alert-outline-coloured.alert-warning .alert-icon {
    background-color: #ff9800; }
  .alert-outline.alert-danger .alert-icon,
  .alert-outline-coloured.alert-danger .alert-icon {
    background-color: #e51c23; }
  .alert-outline.alert-light .alert-icon,
  .alert-outline-coloured.alert-light .alert-icon {
    background-color: #f8f9fa; }
  .alert-outline.alert-dark .alert-icon,
  .alert-outline-coloured.alert-dark .alert-icon {
    background-color: #354052; }
  .alert-outline.alert-tertiary .alert-icon,
  .alert-outline-coloured.alert-tertiary .alert-icon {
    background-color: #5997eb; }
  .alert-outline.alert-gray .alert-icon,
  .alert-outline-coloured.alert-gray .alert-icon {
    background-color: #6c757d; }
  .alert-outline.alert-gray2 .alert-icon,
  .alert-outline-coloured.alert-gray2 .alert-icon {
    background-color: #dee2e6; }
  .alert-outline.alert-dark-red .alert-icon,
  .alert-outline-coloured.alert-dark-red .alert-icon {
    background-color: #EB5757; }
  .alert-outline.alert-dark-green .alert-icon,
  .alert-outline-coloured.alert-dark-green .alert-icon {
    background-color: #A0C8A9; }
  .alert-outline.alert-dark-orange .alert-icon,
  .alert-outline-coloured.alert-dark-orange .alert-icon {
    background-color: #EE9E07; }
  .alert-outline.alert-dark-blue .alert-icon,
  .alert-outline-coloured.alert-dark-blue .alert-icon {
    background-color: #2D9CDB; }
  .alert-outline.alert-dark-purple .alert-icon,
  .alert-outline-coloured.alert-dark-purple .alert-icon {
    background-color: #63619D; }
  .alert-outline.alert-light-red .alert-icon,
  .alert-outline-coloured.alert-light-red .alert-icon {
    background-color: #F6999A; }
  .alert-outline.alert-light-green .alert-icon,
  .alert-outline-coloured.alert-light-green .alert-icon {
    background-color: #93EFA9; }
  .alert-outline.alert-light-orange .alert-icon,
  .alert-outline-coloured.alert-light-orange .alert-icon {
    background-color: #F9E6C5; }
  .alert-outline.alert-light-blue .alert-icon,
  .alert-outline-coloured.alert-light-blue .alert-icon {
    background-color: #56CCF2; }
  .alert-outline.alert-light-purple .alert-icon,
  .alert-outline-coloured.alert-light-purple .alert-icon {
    background-color: #E0DFFF; }

.alert-outline-coloured.alert-primary .alert-message {
  border-color: #2c7be5; }

.alert-outline-coloured.alert-secondary .alert-message {
  border-color: #9D7BD8; }

.alert-outline-coloured.alert-success .alert-message {
  border-color: #4CAF50; }

.alert-outline-coloured.alert-info .alert-message {
  border-color: #47BAC1; }

.alert-outline-coloured.alert-warning .alert-message {
  border-color: #ff9800; }

.alert-outline-coloured.alert-danger .alert-message {
  border-color: #e51c23; }

.alert-outline-coloured.alert-light .alert-message {
  border-color: #f8f9fa; }

.alert-outline-coloured.alert-dark .alert-message {
  border-color: #354052; }

.alert-outline-coloured.alert-tertiary .alert-message {
  border-color: #5997eb; }

.alert-outline-coloured.alert-gray .alert-message {
  border-color: #6c757d; }

.alert-outline-coloured.alert-gray2 .alert-message {
  border-color: #dee2e6; }

.alert-outline-coloured.alert-dark-red .alert-message {
  border-color: #EB5757; }

.alert-outline-coloured.alert-dark-green .alert-message {
  border-color: #A0C8A9; }

.alert-outline-coloured.alert-dark-orange .alert-message {
  border-color: #EE9E07; }

.alert-outline-coloured.alert-dark-blue .alert-message {
  border-color: #2D9CDB; }

.alert-outline-coloured.alert-dark-purple .alert-message {
  border-color: #63619D; }

.alert-outline-coloured.alert-light-red .alert-message {
  border-color: #F6999A; }

.alert-outline-coloured.alert-light-green .alert-message {
  border-color: #93EFA9; }

.alert-outline-coloured.alert-light-orange .alert-message {
  border-color: #F9E6C5; }

.alert-outline-coloured.alert-light-blue .alert-message {
  border-color: #56CCF2; }

.alert-outline-coloured.alert-light-purple .alert-message {
  border-color: #E0DFFF; }

.alert-icon {
  padding: 0.95rem 0.95rem;
  background: rgba(255, 255, 255, 0.1); }

.alert-message {
  padding: 0.95rem 0.95rem;
  width: 100%;
  box-sizing: border-box; }

.rounded-lg {
  border-radius: 0.45rem !important; }

.rounded-top-lg {
  border-top-left-radius: 0.45rem !important;
  border-top-right-radius: 0.45rem !important; }

.rounded-right-lg {
  border-top-right-radius: 0.45rem !important;
  border-bottom-right-radius: 0.45rem !important; }

.rounded-bottom-lg {
  border-bottom-right-radius: 0.45rem !important;
  border-bottom-left-radius: 0.45rem !important; }

.rounded-left-lg {
  border-top-left-radius: 0.45rem !important;
  border-bottom-left-radius: 0.45rem !important; }

.rounded-sm {
  border-radius: 0.15rem !important; }

.rounded-top-sm {
  border-top-left-radius: 0.15rem !important;
  border-top-right-radius: 0.15rem !important; }

.rounded-right-sm {
  border-top-right-radius: 0.15rem !important;
  border-bottom-right-radius: 0.15rem !important; }

.rounded-bottom-sm {
  border-bottom-right-radius: 0.15rem !important;
  border-bottom-left-radius: 0.15rem !important; }

.rounded-left-sm {
  border-top-left-radius: 0.15rem !important;
  border-bottom-left-radius: 0.15rem !important; }

.cursor-grab {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab; }

.cursor-pointer {
  cursor: pointer; }

.absolute-top {
  position: absolute;
  top: 0;
  right: 0;
  left: 0; }

body {
  color: #2E2210; }

header.upward_header {
  color: #fff;
  z-index: 1099; }
  header.upward_header.header_opaque .navbar {
    background-color: rgba(0, 0, 0, 0.85); }
  header.upward_header.upward_header_inverted {
    color: #000; }
    header.upward_header.upward_header_inverted.header_opaque .navbar {
      background-color: rgba(255, 255, 255, 0.85); }
    header.upward_header.upward_header_inverted .navbar .navbar-brand svg {
      fill: #000; }
  header.upward_header.header_scrolling nav.navbar {
    padding: 12px 0; }
  header.upward_header nav.navbar {
    border: 0;
    box-shadow: none;
    background-color: transparent;
    transition: all 800ms ease;
    padding: 40px 0; }
    header.upward_header nav.navbar .navbar-brand {
      padding: 0.25rem 0 0; }
      header.upward_header nav.navbar .navbar-brand svg {
        fill: #fff;
        width: 130px;
        height: auto; }
    header.upward_header nav.navbar .btn-lg, header.upward_header nav.navbar .btn-group-lg > .btn {
      padding: 8px 32px; }
    header.upward_header nav.navbar .btn-lg.btn-dark-green, header.upward_header nav.navbar .btn-group-lg > .btn-dark-green.btn {
      background-color: #93EFA9;
      border-color: #93EFA9; }
    @media (max-width: 991.98px) {
      header.upward_header nav.navbar {
        padding: 30px 0; }
        header.upward_header nav.navbar .navbar-brand svg {
          width: 110px; } }
  header.upward_header.profile_header.header_scrolling nav.navbar {
    padding: 10px 0; }
    header.upward_header.profile_header.header_scrolling nav.navbar .navbar-brand svg {
      width: 100px; }

.upward_header_dropdown {
  margin-top: 0.3rem;
  /* https://app.asana.com/0/1154125598542818/1155406522809037 fix for .dropdown-head overflowing out of the menu*/ }
  .upward_header_dropdown .btn {
    background-color: transparent;
    border: 0;
    box-shadow: none;
    font-size: 16px; }
    .upward_header_dropdown .btn.dropdown-toggle:after {
      margin-left: 0.5rem; }
    .upward_header_dropdown .btn:active {
      background-color: rgba(0, 0, 0, 0.25) !important; }
  .upward_header_dropdown .dropdown-menu {
    background-color: rgba(0, 0, 0, 0.9);
    color: #e6e6e6;
    border: 0; }
    .upward_header_dropdown .dropdown-menu .dropdown-item {
      color: #f2f2f2;
      border: 0; }
      .upward_header_dropdown .dropdown-menu .dropdown-item.dropdown-head {
        font-weight: bold;
        margin-left: -10px; }
      .upward_header_dropdown .dropdown-menu .dropdown-item:hover {
        color: #fff;
        background-color: rgba(0, 0, 0, 0.25); }
  .upward_header_dropdown .loginMargin {
    margin-left: 0 !important; }
    .upward_header_dropdown .loginMargin span {
      margin-left: -10px; }
  .upward_header_dropdown.dropdown_inverted .dropdown-menu {
    background-color: #fff;
    left: 50%;
    right: auto;
    transform: translate(-50%, 0); }
    .upward_header_dropdown.dropdown_inverted .dropdown-menu a {
      color: #f2f2f2 !important; }
    .upward_header_dropdown.dropdown_inverted .dropdown-menu a:hover {
      color: #EE9E07 !important; }
  .upward_header_dropdown.dropdown_inverted .btn {
    color: #000; }
  .upward_header_dropdown.active .btn span {
    border-bottom: 2px solid #fff;
    padding-bottom: 8px; }
  .upward_header_dropdown.upward_header_dropdown_orange .dropdown-menu .dropdown-item:hover {
    color: #EE9E07; }
  .upward_header_dropdown.upward_header_dropdown_orange.active .btn span {
    border-bottom-color: #EE9E07; }
  .upward_header_dropdown.upward_header_dropdown_purple .dropdown-menu .dropdown-item:hover {
    color: #63619D; }
  .upward_header_dropdown.upward_header_dropdown_purple.active .btn span {
    border-bottom-color: #63619D; }
  .upward_header_dropdown.upward_header_dropdown_green .dropdown-menu .dropdown-item:hover {
    color: #A0C8A9; }
  .upward_header_dropdown.upward_header_dropdown_green.active .btn span {
    border-bottom-color: #A0C8A9; }
  .upward_header_dropdown.upward_header_dropdown_red .dropdown-menu .dropdown-item:hover {
    color: #EB5757; }
  .upward_header_dropdown.upward_header_dropdown_red.active .btn span {
    border-bottom-color: #EB5757; }
  .upward_header_dropdown.upward_header_dropdown_blue .dropdown-menu .dropdown-item:hover {
    color: #2D9CDB; }
  .upward_header_dropdown.upward_header_dropdown_blue.active .btn span {
    border-bottom-color: #2D9CDB; }
  @media (max-width: 1199.98px) {
    .upward_header_dropdown h6 {
      color: #fff;
      font-weight: bold; }
    .upward_header_dropdown a {
      padding-left: 35px; } }

.upward_header.upward_header_inverted .upward_header_dropdown .btn {
  color: #000; }

.upward_header.upward_header_inverted .btn.btn-outline-light {
  color: #000;
  border-color: #000; }

.upward_header.upward_header_inverted .btn:hover {
  color: #000;
  background-color: whitesmoke; }

.find_invite_section .upward_header_dropdown button {
  color: #000 !important; }

.upward_main {
  height: 900px;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-color: rgba(0, 0, 0, 0.9);
  background-size: cover;
  background-position: center bottom;
  color: #fff;
  font-size: 18px; }
  .upward_main h2 {
    color: #fff;
    font-size: 68px;
    margin-bottom: 30px;
    line-height: 0.93; }
  .upward_main p {
    margin-bottom: 2rem; }
  .upward_main .upward_main_details {
    padding-top: 22%;
    margin-left: 5rem;
    width: 45vw;
    max-width: 750px; }
  .upward_main.upward_enterprise_main .upward_main_details {
    padding: 250px 0;
    width: 55vw; }
    @media (max-width: 991.98px) {
      .upward_main.upward_enterprise_main .upward_main_details {
        width: 100%;
        /*padding : 200px 0 50px;*/
        padding-top: 22%;
        margin-left: 0;
        padding-bottom: 0; } }
    @media (max-width: 575.98px) {
      .upward_main.upward_enterprise_main .upward_main_details {
        padding-top: 100px; } }
  .upward_main .vertical_list_display {
    position: absolute;
    right: 20px;
    top: 220px;
    font-size: 16px; }
    .upward_main .vertical_list_display ul li {
      margin-bottom: 14px;
      text-align: right; }
      .upward_main .vertical_list_display ul li a {
        color: #fff;
        text-decoration: none;
        padding-bottom: 2px;
        position: relative; }
        .upward_main .vertical_list_display ul li a:after {
          content: "";
          border-bottom: 1px solid #fff;
          position: absolute;
          bottom: 0;
          right: 0;
          width: 100%;
          transition: width 200ms ease-in; }
          @media (prefers-reduced-motion: reduce) {
            .upward_main .vertical_list_display ul li a:after {
              transition: none; } }
        .upward_main .vertical_list_display ul li a:hover:after {
          width: 0; }
  @media (max-width: 991.98px) {
    .upward_main {
      /* .upward_main_details, h2, .vertical_list_display edited by Amit Sharma to show collaped list*/ }
      .upward_main .upward_main_details {
        margin-bottom: 20px;
        margin-left: 0;
        width: auto; }
      .upward_main h2 {
        font-size: 48px;
        margin-bottom: 20px;
        line-height: 112%;
        letter-spacing: -0.01em; } }
  @media (max-width: 575.98px) {
    .upward_main .upward_main_details {
      padding-top: 100px; } }
  .upward_main.upward_employer_main {
    /* full screen on screens less than md breakpoints */ }
    @media (min-width: 992px) {
      .upward_main.upward_employer_main .upward_vertical_recent_items {
        bottom: -90px; } }
    @media (max-width: 991.98px) {
      .upward_main.upward_employer_main {
        min-height: 100vh; }
        .upward_main.upward_employer_main .dropdown-menu {
          left: 25%; } }

@media (max-width: 991.98px) {
  .upward_main {
    height: auto; } }

.upward_homepage_main .upward_vertical_recent_items, .upward_main .upward_vertical_recent_items {
  padding: 0;
  margin: 0 auto; }
  @media (min-width: 992px) {
    .upward_homepage_main .upward_vertical_recent_items, .upward_main .upward_vertical_recent_items {
      width: 350px;
      position: absolute;
      bottom: 10px; }
      .upward_homepage_main .upward_vertical_recent_items.recent_job_items, .upward_main .upward_vertical_recent_items.recent_job_items {
        right: 0; }
      .upward_homepage_main .upward_vertical_recent_items.recent_candidate_items, .upward_main .upward_vertical_recent_items.recent_candidate_items {
        left: 0; } }
  .upward_homepage_main .upward_vertical_recent_items .recent_item:nth-child(2), .upward_main .upward_vertical_recent_items .recent_item:nth-child(2) {
    display: none; }

.upward_homepage_main .upward_vertical_recent_items {
  bottom: -170px; }

.upward_count_display {
  padding: 6rem 0; }
  .upward_count_display h2 {
    font-size: 80px;
    margin-bottom: 0; }
  .upward_count_display p {
    font-size: 18px; }
  .upward_count_display .hireImg {
    box-shadow: 10px 10px 5px #F9E6C5;
    -moz-box-shadow: 10px 10px 5px #F9E6C5;
    -webkit-box-shadow: 10px 10px 5px #F9E6C5;
    -khtml-box-shadow: 10px 10px 5px #F9E6C5; }
  .upward_count_display .orange_plus {
    font-size: 24px; }
  @media (max-width: 767.98px) {
    .upward_count_display h2 {
      font-size: 96px; }
    .upward_count_display .first_num {
      margin-bottom: 62px; }
      .upward_count_display .first_num p {
        margin-bottom: 62px; } }
  @media (max-width: 575.98px) {
    .upward_count_display {
      padding: 62px 0; }
      .upward_count_display h2 {
        font-size: 66px; }
      .upward_count_display h4 {
        margin-bottom: 62px; } }
  .upward_count_display.upward_count_display_orange {
    background-color: #F9E6C5; }
    .upward_count_display.upward_count_display_orange .upward_count_vertical {
      color: #EE9E07; }
  .upward_count_display.upward_count_display_purple {
    background-color: #E0DFFF; }
    .upward_count_display.upward_count_display_purple .upward_count_vertical {
      color: #63619D; }
  .upward_count_display.upward_count_display_green {
    background-color: #93EFA9; }
    .upward_count_display.upward_count_display_green .upward_count_vertical {
      color: #A0C8A9; }
  .upward_count_display.upward_count_display_red {
    background-color: #F6999A; }
    .upward_count_display.upward_count_display_red .upward_count_vertical {
      color: #EB5757; }
  .upward_count_display.upward_count_display_blue {
    background-color: #56CCF2; }
    .upward_count_display.upward_count_display_blue .upward_count_vertical {
      color: #2D9CDB; }
  .upward_count_display.upward_count_display_green {
    background-color: #A0C8A9; }

.recent_candidates_display.recent_table_display {
  margin-top: -200px;
  position: relative;
  z-index: 199; }
  .recent_candidates_display.recent_table_display .recent_content_holder {
    width: 700px; }

@media (max-width: 991.98px) {
  .recent_candidates_display.recent_table_display {
    margin-top: -140px; }
    .recent_candidates_display.recent_table_display .recent_content_holder {
      width: 100%;
      padding: 20px; } }

.recent_table_display .recent_content_holder {
  margin: 0 auto;
  background-color: #fff;
  color: #2E2210;
  border-radius: 24px;
  padding: 20px 30px;
  position: relative;
  height: 360px;
  overflow: hidden; }
  @media (max-width: 767.98px) {
    .recent_table_display .recent_content_holder {
      height: 320px;
      padding: 20px 15px; } }
  .recent_table_display .recent_content_holder:after {
    content: "";
    position: absolute;
    height: 25%;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.5);
    background: -moz-linear-gradient(0deg, white 0%, rgba(255, 255, 255, 0) 100%);
    background: -webkit-linear-gradient(0deg, white 0%, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(0deg, white 0%, rgba(255, 255, 255, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1); }
  .recent_table_display .recent_content_holder header.recent_table_item {
    padding-top: 2px;
    padding-bottom: 8px;
    margin-bottom: 4px;
    border: 0 !important; }
  .recent_table_display .recent_content_holder .recent_table_item {
    padding: 12px 24px 10px;
    font-size: 18px;
    margin-bottom: 10px;
    font-size: 16px;
    -webkit-box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.1);
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 24px;
    transition: all 300ms ease-in; }
    @media (prefers-reduced-motion: reduce) {
      .recent_table_display .recent_content_holder .recent_table_item {
        transition: none; } }
    .recent_table_display .recent_content_holder .recent_table_item small {
      font-weight: bold;
      font-size: 0.9em; }
    .recent_table_display .recent_content_holder .recent_table_item:first-child {
      border: 1px solid #ccc; }
    .recent_table_display .recent_content_holder .recent_table_item-enter {
      opacity: 0;
      transform: scale(0); }
    .recent_table_display .recent_content_holder .recent_table_item-enter-active {
      opacity: 1;
      transform: scale(1.05); }
    .recent_table_display .recent_content_holder .recent_table_item-enter-done {
      opacity: 1;
      transform: scale(1); }
    .recent_table_display .recent_content_holder .recent_table_item_link {
      position: relative;
      z-index: 99;
      display: none; }
    .recent_table_display .recent_content_holder .recent_table_item:hover .recent_table_item_link {
      display: block; }
  .recent_table_display .recent_content_holder .ps--active-y .ps__rail-y {
    opacity: 0.5;
    width: 8px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 4px; }

.recent_table_display.recent_table_display_orange .recent_table_item:first-child {
  border-color: #f9b32f; }

.recent_table_display.recent_table_display_purple .recent_table_item:first-child {
  border-color: #8280b1; }

.recent_table_display.recent_table_display_green .recent_table_item:first-child {
  border-color: #c0dbc6; }

.recent_table_display.recent_table_display_red .recent_table_item:first-child {
  border-color: #f08585; }

.recent_table_display.recent_table_display_blue .recent_table_item:first-child {
  border-color: #59b1e2; }

.upward_post_boards {
  padding: 88px 0; }
  .upward_post_boards h2 {
    font-size: 68px;
    text-align: center;
    margin-bottom: 75px;
    line-height: 1; }
  @media (max-width: 767.98px) {
    .upward_post_boards h2 {
      font-size: 48px; } }
  .upward_post_boards .top_board_image_holder {
    position: relative; }
    .upward_post_boards .top_board_image_holder .board_image {
      max-width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 9; }
    .upward_post_boards .top_board_image_holder .top_board_logo {
      position: absolute;
      width: 300px;
      z-index: 99; }
    @media (max-width: 1199.98px) {
      .upward_post_boards .top_board_image_holder .top_board_logo {
        width: 200px;
        top: calc( 50% - 40px);
        left: calc( 50% - 100px); } }
    @media (max-width: 991.98px) {
      .upward_post_boards .top_board_image_holder .top_board_logo {
        width: 200px;
        top: calc( 50% - 30px);
        left: calc( 50% - 100px); }
      .upward_post_boards .top_board_image_holder .board_abstract_bg {
        opacity: 0.4; } }
    @media (max-width: 767.98px) {
      .upward_post_boards .top_board_image_holder .top_board_logo {
        width: 160px;
        top: calc( 50% - 20px);
        left: calc( 50% - 80px); }
      .upward_post_boards .top_board_image_holder .board_abstract_bg {
        opacity: 0.2; } }

.upward_agencies, .upward_work_with {
  padding: 90px 0;
  background: #fff; }
  .upward_agencies h3, .upward_work_with h3 {
    font-size: 48px;
    line-height: 105%;
    margin-bottom: 40px; }

.upward_work_with .dropdown-menu {
  border: 1px solid #adb5bd; }
  .upward_work_with .dropdown-menu .dropdown-item:hover {
    color: #000 !important; }

.upward_work_with.upward_work_with_orange {
  background-color: #F9E6C5; }

.upward_work_with.upward_work_with_purple {
  background-color: #E0DFFF; }

.upward_work_with.upward_work_with_green {
  background-color: #93EFA9; }

.upward_work_with.upward_work_with_red {
  background-color: #F6999A; }

.upward_work_with.upward_work_with_blue {
  background-color: #56CCF2; }

.upward_advantage {
  background-color: #F8F8F8;
  padding: 102px 0; }
  .upward_advantage h3 {
    font-size: 48px;
    color: #2E2210;
    margin-bottom: 75px; }
  .upward_advantage p {
    color: #2E2210;
    font-size: 18px; }
  .upward_advantage img {
    max-height: 50px;
    margin: 0 auto;
    margin-bottom: 30px;
    display: block;
    filter: gray;
    /* IE6-9 */
    -webkit-filter: grayscale(1);
    /* Google Chrome, Safari 6+ & Opera 15+ */
    filter: grayscale(1); }
  @media (max-width: 991.98px) {
    .upward_advantage h3 {
      margin-bottom: 40px; } }

.upward_more_details {
  position: relative;
  margin: 80px 0 170px;
  overflow: hidden; }
  .upward_more_details .container {
    position: relative; }
  .upward_more_details .language_wrapper {
    padding: 90px 0; }
    .upward_more_details .language_wrapper h3 {
      font-size: 48px;
      margin-bottom: 1.5rem; }
    .upward_more_details .language_wrapper p {
      font-size: 18px; }
  @media (max-width: 767.98px) {
    .upward_more_details .language_wrapper {
      padding: 45px 0; }
      .upward_more_details .language_wrapper h3 {
        font-size: 48px;
        text-align: center; }
      .upward_more_details .language_wrapper p {
        text-align: center; } }
  .upward_more_details .abstract_container {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    left: -25%; }
    .upward_more_details .abstract_container.abstract_switched {
      -webkit-transform: scaleX(-1);
      -moz-transform: scaleX(-1);
      -o-transform: scaleX(-1);
      transform: scaleX(-1);
      filter: FlipH;
      -ms-filter: "FlipH";
      right: -25%;
      left: auto; }
  @media (max-width: 767.98px) {
    .upward_more_details .abstract_container {
      width: 200%; } }
  .upward_more_details svg {
    height: 100%; }
  .upward_more_details.upward_more_details_orange svg {
    fill: #F9E6C5; }
  .upward_more_details.upward_more_details_purple svg {
    fill: #E0DFFF; }
  .upward_more_details.upward_more_details_green svg {
    fill: #93EFA9; }
  .upward_more_details.upward_more_details_red svg {
    fill: #F6999A; }
  .upward_more_details.upward_more_details_blue svg {
    fill: #56CCF2; }

@media (max-width: 767.98px) {
  .upward_more_details {
    padding: 0 20px;
    margin-bottom: 60px; } }

.upward_proudly_serve {
  padding: 100px 0; }
  .upward_proudly_serve h3 {
    font-size: 68px;
    line-height: 112.6%;
    color: #2E2210;
    margin-bottom: 100px; }
  .upward_proudly_serve .proudly_serve_vertical {
    padding: 2rem 2rem 1rem; }
    .upward_proudly_serve .proudly_serve_vertical img {
      width: 100%;
      margin-bottom: 8px;
      opacity: 1;
      transition: opacity 500ms ease; }
    .upward_proudly_serve .proudly_serve_vertical h4 {
      font-size: 24px; }
    .upward_proudly_serve .proudly_serve_vertical:hover img {
      opacity: 0.6; }
  @media (max-width: 767.98px) {
    .upward_proudly_serve h3 {
      font-size: 48px; } }

@keyframes hideshow {
  0% {
    transform: rotateX(0deg); }
  99.2% {
    transform: rotateX(0deg); }
  99.5% {
    transform: rotateX(60deg); }
  99.6% {
    transform: rotateX(60deg); }
  100% {
    transform: rotateX(0deg); } }

.upward_footer {
  background-color: #fff;
  color: #fff;
  padding: 0 0 4rem 0;
  font-size: 15px; }
  .upward_footer a.social_media_icon {
    font-size: 2rem; }
  .upward_footer .footer_logo_wrapper {
    padding: 4rem 0 5rem; }
    .upward_footer .footer_logo_wrapper svg {
      fill: #fff; }
      .upward_footer .footer_logo_wrapper svg .upward_eye {
        animation: hideshow 30s ease infinite;
        transform-origin: 25% 25%; }
  .upward_footer .footer_abstract_wrapper {
    background: #fff;
    position: relative; }
    .upward_footer .footer_abstract_wrapper .abstract_wrapper {
      background: #fff;
      position: relative;
      z-index: 10;
      max-height: 300px;
      overflow: hidden; }
    .upward_footer .footer_abstract_wrapper .abstract_color_extend {
      width: 46%;
      position: absolute;
      left: 54%;
      top: 0;
      bottom: 0;
      background: #fff; }
  .upward_footer ul li {
    padding: 0.4rem 0; }
    .upward_footer ul li.active a {
      text-decoration: underline; }
  .upward_footer a {
    color: #fff;
    font-size: inherit; }
    .upward_footer a:hover {
      color: #fff;
      text-decoration: underline; }
  .upward_footer h5 {
    font-size: 1.2em;
    color: #fff; }
  .upward_footer.footer_orange {
    background-color: #EE9E07; }
    .upward_footer.footer_orange .footer_abstract_wrapper svg {
      fill: #EE9E07; }
    .upward_footer.footer_orange .footer_abstract_wrapper .abstract_color_extend {
      background-color: #EE9E07; }
  .upward_footer.footer_purple {
    background-color: #63619D; }
    .upward_footer.footer_purple .footer_abstract_wrapper svg {
      fill: #63619D; }
    .upward_footer.footer_purple .footer_abstract_wrapper .abstract_color_extend {
      background-color: #63619D; }
  .upward_footer.footer_green {
    background-color: #A0C8A9; }
    .upward_footer.footer_green .footer_abstract_wrapper svg {
      fill: #A0C8A9; }
    .upward_footer.footer_green .footer_abstract_wrapper .abstract_color_extend {
      background-color: #A0C8A9; }
  .upward_footer.footer_red {
    background-color: #EB5757; }
    .upward_footer.footer_red .footer_abstract_wrapper svg {
      fill: #EB5757; }
    .upward_footer.footer_red .footer_abstract_wrapper .abstract_color_extend {
      background-color: #EB5757; }
  .upward_footer.footer_blue {
    background-color: #2D9CDB; }
    .upward_footer.footer_blue .footer_abstract_wrapper svg {
      fill: #2D9CDB; }
    .upward_footer.footer_blue .footer_abstract_wrapper .abstract_color_extend {
      background-color: #2D9CDB; }
  .upward_footer.footer_green a, .upward_footer.footer_green h5 {
    color: #000; }
  .upward_footer.footer_green svg {
    fill: #000; }
  @media (max-width: 991.98px) {
    .upward_footer div.float-left {
      margin: 0 auto;
      float: none !important; }
    .upward_footer .row.justify-content-lg-center {
      text-align: center; } }

.vertical-pos {
  margin-top: -15%; }
  @media (max-width: 1199.98px) {
    .vertical-pos {
      margin-top: 0; } }

.upward_vertical_notifications .vertRow {
  justify-content: flex-end; }

.upward_vertical_notifications .vertical_recent {
  padding: 15px;
  padding-left: 43px;
  position: relative;
  background: #FFFFFF;
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  margin-bottom: 1rem;
  z-index: 999; }
  .upward_vertical_notifications .vertical_recent .vertical_recent_icon {
    position: absolute;
    top: 15px;
    left: 15px;
    font-size: 1.2em; }
  .upward_vertical_notifications .vertical_recent header {
    font-weight: bold;
    margin-bottom: 16px;
    font-size: 12px;
    text-transform: uppercase; }
  .upward_vertical_notifications .vertical_recent span.vertical_recent_time {
    position: absolute;
    right: 8px;
    top: 8px;
    padding: 8px 14px 7px;
    background-color: #34302A;
    text-transform: none;
    color: #fff;
    border-radius: 30px; }
  .upward_vertical_notifications .vertical_recent h4 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 2px; }
  .upward_vertical_notifications .vertical_recent p {
    margin-bottom: 0;
    line-height: 1.2; }

.upward_vertical_notifications.upward_vertical_recent_orange .vertical_recent_job_icon {
  color: #EE9E07; }

.upward_vertical_notifications.upward_vertical_recent_purple .vertical_recent_job_icon {
  color: #63619D; }

.upward_vertical_notifications.upward_vertical_recent_green .vertical_recent_job_icon {
  color: #A0C8A9; }

.upward_vertical_notifications.upward_vertical_recent_red .vertical_recent_job_icon {
  color: #EB5757; }

.upward_vertical_notifications.upward_vertical_recent_blue .vertical_recent_job_icon {
  color: #2D9CDB; }

@media (max-width: 991.98px) {
  .upward_vertical_notifications {
    position: relative;
    left: 0;
    top: 50px; } }

.upward_homepage_main {
  background-color: #F8F8F8;
  padding: 115px 0; }
  .upward_homepage_main h2 {
    font-size: 68px;
    line-height: 93.6%;
    margin-bottom: 40px;
    white-space: nowrap; }
  .upward_homepage_main .upward_main_details {
    padding-top: 20vh;
    margin-left: 5rem;
    width: 40vw;
    max-width: 600px; }
  .upward_homepage_main .job_search_form input {
    line-height: 93.6%;
    font-size: 16px;
    border: none;
    background: transparent;
    border-bottom: 1px solid #2E2210;
    border-radius: 0;
    padding: 20px; }
    .upward_homepage_main .job_search_form input#locationInput {
      border-left: 1px solid #2E2210; }
    .upward_homepage_main .job_search_form input:focus {
      box-shadow: none; }
  @media (max-width: 1199.98px) {
    .upward_homepage_main {
      padding: 115px 0 20px; }
      .upward_homepage_main h2 {
        font-size: 48px; }
      .upward_homepage_main .job_search_form {
        display: grid; }
        .upward_homepage_main .job_search_form .form-group {
          display: block; }
        .upward_homepage_main .job_search_form input {
          display: grid;
          width: 100%;
          border-left: none !important; }
        .upward_homepage_main .job_search_form button {
          width: 150px;
          margin: 20px auto 0;
          font-size: 18px; }
      .upward_homepage_main .job_search_form2 {
        display: block !important; }
      .upward_homepage_main .upward_main_details {
        width: 50vw; } }
  .upward_homepage_main_orange .homepage_img_wrapper .homepage_abstract_1 svg {
    fill: #EE9E07; }
  .upward_homepage_main_orange .homepage_img_wrapper .homepage_abstract_2 svg {
    fill: #F9E6C5; }
  .upward_homepage_main_purple .homepage_img_wrapper .homepage_abstract_1 svg {
    fill: #63619D; }
  .upward_homepage_main_purple .homepage_img_wrapper .homepage_abstract_2 svg {
    fill: #E0DFFF; }
  .upward_homepage_main_green .homepage_img_wrapper .homepage_abstract_1 svg {
    fill: #A0C8A9; }
  .upward_homepage_main_green .homepage_img_wrapper .homepage_abstract_2 svg {
    fill: #93EFA9; }
  .upward_homepage_main_red .homepage_img_wrapper .homepage_abstract_1 svg {
    fill: #EB5757; }
  .upward_homepage_main_red .homepage_img_wrapper .homepage_abstract_2 svg {
    fill: #F6999A; }
  .upward_homepage_main_blue .homepage_img_wrapper .homepage_abstract_1 svg {
    fill: #2D9CDB; }
  .upward_homepage_main_blue .homepage_img_wrapper .homepage_abstract_2 svg {
    fill: #56CCF2; }

.upward_partner_main .homepage_img_wrapper img {
  max-width: 100%;
  margin: 40px 0 80px; }

@media (max-width: 1439.98px) {
  .upward_partner_main .homepage_img_wrapper .partnerpage_abstract_1 {
    right: -40px; }
  .upward_partner_main .homepage_img_wrapper .partnerpage_abstract_2 {
    left: 30px; } }

@media (max-width: 1199.98px) {
  .upward_partner_main .homepage_img_wrapper .partnerpage_abstract_1 {
    right: -100px; }
  .upward_partner_main .homepage_img_wrapper .partnerpage_abstract_2 {
    left: -30px; } }

@media (max-width: 1199.98px) {
  .upward_partner_main .homepage_img_wrapper {
    margin-left: -15px;
    margin-right: -15px; }
    .upward_partner_main .homepage_img_wrapper .partnerpage_abstract_1 {
      left: 67%; }
    .upward_partner_main .homepage_img_wrapper .partnerpage_abstract_2 {
      left: 4%; } }

@media (max-width: 767.98px) {
  .upward_partner_main .homepage_img_wrapper {
    overflow-x: hidden;
    margin-left: -15px;
    margin-right: -15px; } }

.upward_partner_main_orange .homepage_img_wrapper .partnerpage_abstract_2 svg {
  fill: #EE9E07; }

.upward_partner_main_orange .homepage_img_wrapper .partnerpage_abstract_1 svg {
  fill: #F9E6C5; }

.upward_partner_main_purple .homepage_img_wrapper .partnerpage_abstract_2 svg {
  fill: #63619D; }

.upward_partner_main_purple .homepage_img_wrapper .partnerpage_abstract_1 svg {
  fill: #E0DFFF; }

.upward_partner_main_green .homepage_img_wrapper .partnerpage_abstract_2 svg {
  fill: #A0C8A9; }

.upward_partner_main_green .homepage_img_wrapper .partnerpage_abstract_1 svg {
  fill: #93EFA9; }

.upward_partner_main_red .homepage_img_wrapper .partnerpage_abstract_2 svg {
  fill: #EB5757; }

.upward_partner_main_red .homepage_img_wrapper .partnerpage_abstract_1 svg {
  fill: #F6999A; }

.upward_partner_main_blue .homepage_img_wrapper .partnerpage_abstract_2 svg {
  fill: #2D9CDB; }

.upward_partner_main_blue .homepage_img_wrapper .partnerpage_abstract_1 svg {
  fill: #56CCF2; }

.homepage_img_wrapper {
  position: relative;
  margin-top: 40px; }
  .homepage_img_wrapper img {
    position: relative;
    z-index: 90; }
  .homepage_img_wrapper .homepage_abstract_wrapper {
    position: absolute; }
  .homepage_img_wrapper .homepage_abstract_1 {
    z-index: 99;
    top: -30px;
    left: 40%;
    width: 320px; }
  .homepage_img_wrapper .homepage_abstract_2 {
    z-index: 50;
    bottom: 0;
    left: 0;
    width: 550px; }
  .homepage_img_wrapper .partnerpage_abstract_1 {
    z-index: 99;
    bottom: 60px;
    right: 0;
    width: 240px; }
  .homepage_img_wrapper .partnerpage_abstract_2 {
    z-index: 50;
    bottom: 33px;
    left: 80px;
    width: 100%;
    max-width: 330px; }
  .homepage_img_wrapper .home_page_abstract_1-enter {
    opacity: 0.1;
    top: -100px; }
  .homepage_img_wrapper .home_page_abstract_1-enter-done {
    top: -20px;
    opacity: 1;
    transition: all 1s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .homepage_img_wrapper .home_page_abstract_1-enter-done {
        transition: none; } }
  .homepage_img_wrapper .home_page_abstract_1-exit {
    top: -20px;
    opacity: 1; }
  .homepage_img_wrapper .home_page_abstract_1-exit-done {
    top: -100px;
    opacity: 0.1;
    transition: all 1s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .homepage_img_wrapper .home_page_abstract_1-exit-done {
        transition: none; } }
  .homepage_img_wrapper .home_page_abstract_2-enter {
    opacity: 0.1;
    bottom: -100px; }
  .homepage_img_wrapper .home_page_abstract_2-enter-done {
    bottom: 0;
    opacity: 1;
    transition: all 1s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .homepage_img_wrapper .home_page_abstract_2-enter-done {
        transition: none; } }
  .homepage_img_wrapper .home_page_abstract_2-exit {
    bottom: 0;
    opacity: 1; }
  .homepage_img_wrapper .home_page_abstract_2-exit-done {
    bottom: -100px;
    opacity: 0.1;
    transition: all 1s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .homepage_img_wrapper .home_page_abstract_2-exit-done {
        transition: none; } }
  .homepage_img_wrapper .partnerpage_abstract_1 {
    transform-origin: -120px 50%; }
  .homepage_img_wrapper .partner_page_abstract_1-enter {
    opacity: 0.1;
    transform: rotate(-45deg); }
  .homepage_img_wrapper .partner_page_abstract_1-enter-done {
    opacity: 1;
    transform: rotate(0deg);
    transition: all 1s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .homepage_img_wrapper .partner_page_abstract_1-enter-done {
        transition: none; } }
  .homepage_img_wrapper .partner_page_abstract_1-exit {
    opacity: 1;
    transform: rotate(0deg); }
  .homepage_img_wrapper .partner_page_abstract_1-exit-done {
    opacity: 0.1;
    transform: rotate(-45deg);
    transition: all 1s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .homepage_img_wrapper .partner_page_abstract_1-exit-done {
        transition: none; } }
  .homepage_img_wrapper .partnerpage_abstract_2 {
    transform-origin: top right; }
  .homepage_img_wrapper .partner_page_abstract_2-enter {
    opacity: 0.1;
    transform: rotate(45deg); }
  .homepage_img_wrapper .partner_page_abstract_2-enter-done {
    opacity: 1;
    transform: rotate(0deg);
    transition: all 1s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .homepage_img_wrapper .partner_page_abstract_2-enter-done {
        transition: none; } }
  .homepage_img_wrapper .partner_page_abstract_2-exit {
    opacity: 1;
    transform: rotate(0deg); }
  .homepage_img_wrapper .partner_page_abstract_2-exit-done {
    opacity: 0.1;
    transform: rotate(45deg);
    transition: all 1s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .homepage_img_wrapper .partner_page_abstract_2-exit-done {
        transition: none; } }

.upward_get_matched {
  line-height: 105.6%;
  min-height: 917px;
  padding: 105px 0 90px 0;
  background: #fff; }
  .upward_get_matched h3 {
    font-size: 48px;
    line-height: 105%;
    margin-bottom: 50px;
    margin-top: 50px; }
  .upward_get_matched p {
    font-size: 18px;
    line-height: 22px;
    font-weight: 300;
    width: 412px; }
  .upward_get_matched.upward_get_matched_orange {
    background-color: #F9E6C5; }
  .upward_get_matched.upward_get_matched_purple {
    background-color: #E0DFFF; }
  .upward_get_matched.upward_get_matched_green {
    background-color: #93EFA9; }
  .upward_get_matched.upward_get_matched_red {
    background-color: #F6999A; }
  .upward_get_matched.upward_get_matched_blue {
    background-color: #56CCF2; }
  @media (max-width: 1199.98px) {
    .upward_get_matched div {
      text-align: center !important; }
    .upward_get_matched h3 {
      margin: 50px 0;
      text-align: center; }
    .upward_get_matched p {
      text-align: center;
      width: auto; } }

.upward_find_better {
  padding-top: 160px; }
  .upward_find_better h3 {
    font-size: 68px;
    line-height: 93.6%;
    color: #2E2210;
    font-weight: 300; }
  @media (max-width: 1199.98px) {
    .upward_find_better {
      padding-top: 80px; }
      .upward_find_better h3 {
        font-size: 48px;
        margin: 0 auto; } }

.home_details {
  line-height: 22px; }
  .home_details .language_wrapper a {
    font-size: 18px;
    font-weight: bold; }
  .home_details .painlessText h3 {
    width: 446px; }
  @media (max-width: 1199.98px) {
    .home_details {
      margin-top: 35px;
      margin-bottom: 0; }
      .home_details .text-left {
        text-align: center !important; }
      .home_details .painlessText h3 {
        width: 100%; }
      .home_details img {
        height: auto; } }

.upward_get_alerted {
  background-color: #56CCF2;
  padding: 127px 0; }
  .upward_get_alerted h3 {
    font-style: normal;
    font-weight: 300;
    font-size: 48px;
    line-height: 105.6%;
    width: 606px;
    margin: 0 auto 35px; }
  .upward_get_alerted p {
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    width: 515px;
    margin: 0 auto; }
  .upward_get_alerted.upward_get_alerted_orange {
    background-color: #F9E6C5; }
  .upward_get_alerted.upward_get_alerted_purple {
    background-color: #E0DFFF; }
  .upward_get_alerted.upward_get_alerted_green {
    background-color: #93EFA9; }
  .upward_get_alerted.upward_get_alerted_red {
    background-color: #F6999A; }
  .upward_get_alerted.upward_get_alerted_blue {
    background-color: #56CCF2; }
  @media (max-width: 1199.98px) {
    .upward_get_alerted h3, .upward_get_alerted p {
      width: 327px; } }

.upward_subscribe {
  padding: 115px 0 40px 0; }
  .upward_subscribe h3 {
    font-size: 48px;
    line-height: 105.6%;
    color: #2E2210;
    margin-bottom: 40px; }
  .upward_subscribe .form-inline {
    border-bottom: 1px solid #000000;
    display: inline-flex; }
    .upward_subscribe .form-inline input {
      width: 370px;
      font-size: 16px;
      line-height: 93.6%;
      border: none; }
      @media (max-width: 1199.98px) {
        .upward_subscribe .form-inline input {
          margin-bottom: 30px;
          width: 100%; } }
      .upward_subscribe .form-inline input:focus {
        box-shadow: none; }
  .upward_subscribe button {
    font-weight: bold; }
  @media (max-width: 1199.98px) {
    .upward_subscribe .form-inline {
      margin-top: 35px;
      display: block;
      border: none; }
      .upward_subscribe .form-inline input {
        border-bottom: 1px solid #2E2210;
        border-radius: 0; } }

@media (max-width: 1199.98px) {
  header.upward_header {
    position: relative; }
  .upward_more_details .MoreDetails {
    margin-top: 25px; } }

.input-error {
  background-color: #ffedf0 !important;
  color: #c50000 !important;
  border-color: #cd0b0b !important; }

.custom-alert-failure {
  padding: 8px;
  color: #d55557;
  background-color: #fbcdce; }
  .custom-alert-failure .close {
    padding: 0.3rem !important;
    position: absolute;
    top: 0 !important;
    right: 0 !important; }

.borderRadius045 > div {
  border-radius: 0.45rem; }

.roundLgInput > input {
  height: calc(2.2rem + 2px);
  padding: 0.35rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.45rem; }

.roundLgInput .loading_input_wrapper > input {
  height: calc(2.2rem + 2px);
  padding: 0.35rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.45rem; }

.roundLgInput .react-select-container {
  height: calc(2.2rem + 2px);
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.45rem; }

.roundLgInput .react-select-container .react-select__value-container {
  padding: .35rem .7rem; }

.roundLgInput textarea {
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.45rem;
  width: 100%;
  padding: .35rem .7rem;
  border: 1px solid #ced4da; }

.roundLgInput .quill .ql-toolbar.ql-snow {
  border-top-left-radius: 0.45rem;
  border-top-right-radius: 0.45rem;
  border-bottom: none; }

.roundLgInput .quill .ql-toolbar {
  border-bottom: none; }

.roundLgInput .quill .ql-container.ql-snow {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.roundLgInput .react-select__control {
  border-radius: 0.45rem; }

.custom-alert-success {
  padding: 8px;
  color: #909090;
  background-color: #c0e6c1; }
  .custom-alert-success .close {
    padding: 0.3rem !important;
    position: absolute;
    top: 0 !important;
    right: 0 !important; }

.fontSize1point2rem {
  font-size: 1.2rem; }

.jobDatePicker input {
  width: 100%;
  height: calc(2.2rem + 2px);
  padding: 0.35rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.45rem;
  border: 1px solid #ced4da; }

.autoWidthImportant {
  width: auto !important; }

.quill.is-invalid .ql-snow {
  border-color: #cd0b0b !important; }

.quill.is-invalid .ql-snow {
  border-color: #cd0b0b !important; }

.fileInput input {
  font-size: 1rem;
  border-radius: 0.45rem;
  border: 1px solid #ced4da;
  padding: 0.35rem 1rem;
  width: 100%; }

.alert-message {
  padding-right: 1.4rem !important; }

.backgroundColorF8FAFC {
  background-color: #F8FAFC; }

.z_index_2099 {
  z-index: 2099; }

.dropdown-item {
  background-color: #000 !important; }

.mission_main {
  background-color: #fff;
  height: 900px;
  color: #2E2210;
  font-size: 18px;
  padding-top: 210px;
  text-align: center; }
  .mission_main h2 {
    font-weight: 300;
    font-size: 144px;
    line-height: 93.6%;
    margin-bottom: 55px; }
  .mission_main p {
    line-height: 22px; }
  @media (max-width: 1199.98px) {
    .mission_main h2 {
      font-size: 72px; } }

.upward_group_photo {
  padding: 70px 0; }
  .upward_group_photo img {
    max-height: 550px;
    width: 100%; }
  @media (max-width: 1199.98px) {
    .upward_group_photo {
      padding: 25px 0; } }

.upward_who_we_are {
  padding: 90px 0; }
  .upward_who_we_are .language_wrapper {
    margin-bottom: 155px; }
  .upward_who_we_are h3 {
    font-size: 48px;
    line-height: 105%;
    margin-bottom: 25px; }
  .upward_who_we_are p {
    font-weight: 300;
    font-size: 18px;
    line-height: 22px; }
  .upward_who_we_are .btn.btn-secondary.font-weight-bolder {
    font-size: 18px;
    background: #fff;
    color: #34302A;
    border-radius: 25px;
    border: #34302A;
    padding: 5px 25px; }
    .upward_who_we_are .btn.btn-secondary.font-weight-bolder:hover {
      background: #fff;
      color: #34302A;
      border: #34302A; }
  @media (max-width: 1199.98px) {
    .upward_who_we_are h3 {
      margin: 50px 0; } }

.upward_leadership h3 {
  padding: 75px 0;
  font-size: 48px;
  color: #fff;
  line-height: 105.6%; }

.upward_leadership .leaderBox {
  color: #fff;
  font-size: 18px;
  margin-bottom: 95px;
  text-align: center; }
  .upward_leadership .leaderBox img {
    max-width: 100%;
    margin: 0 auto 40px; }
    @media (max-width: 1199.98px) {
      .upward_leadership .leaderBox img {
        margin-bottom: 15px; } }
  .upward_leadership .leaderBox p {
    font-weight: bold;
    font-size: 24px; }
  .upward_leadership .leaderBox a {
    color: #fff;
    text-decoration: underline; }

.upward_leadership.upward_leadership_orange {
  background-color: #EE9E07; }

.upward_leadership.upward_leadership_purple {
  background-color: #63619D; }

.upward_leadership.upward_leadership_green {
  background-color: #A0C8A9; }

.upward_leadership.upward_leadership_red {
  background-color: #EB5757; }

.upward_leadership.upward_leadership_blue {
  background-color: #2D9CDB; }

.upward_hiring {
  padding: 190px 0; }
  .upward_hiring h3 {
    font-size: 144px;
    line-height: 105.6%;
    margin-bottom: 35px; }
  @media (max-width: 1199.98px) {
    .upward_hiring h3 {
      font-size: 72px; } }

.upward_contact {
  padding: 90px 0;
  background-color: #F8F8F8; }
  .upward_contact h3 {
    font-size: 48px;
    line-height: 105.6%; }
  .upward_contact p {
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    padding-bottom: 40px; }
  .upward_contact .companyInfo {
    font-size: 18px;
    line-height: 22px; }
    .upward_contact .companyInfo span {
      font-weight: bold; }
    @media (max-width: 1199.98px) {
      .upward_contact .companyInfo {
        margin-bottom: 25px; } }
  .upward_contact form {
    padding-bottom: 80px; }
  @media (max-width: 1199.98px) {
    .upward_contact button {
      font-size: 18px;
      padding: 5px 25px; } }

.upward_partner_main {
  padding: 110px 0;
  background-color: #fff;
  font-size: 18px; }
  @media (max-width: 1199.98px) {
    .upward_partner_main img {
      height: initial; } }
  .upward_partner_main h2 {
    line-height: 93.6%;
    font-size: 68px;
    margin-top: 80px;
    margin-bottom: 30px; }
    @media (max-width: 1199.98px) {
      .upward_partner_main h2 {
        font-size: 48px;
        margin-top: 25px; } }

.upward_ad_solutions {
  padding: 106px 0;
  font-size: 18px; }
  .upward_ad_solutions h3 {
    font-size: 48px;
    margin-bottom: 70px;
    margin-top: 30px; }
  .upward_ad_solutions h4 {
    font-weight: bold;
    margin-bottom: 25px;
    font-size: 22px; }
  .upward_ad_solutions p {
    line-height: 27px; }
  @media (max-width: 1199.98px) {
    .upward_ad_solutions {
      text-align: center; }
      .upward_ad_solutions h3 {
        margin-bottom: 40px; } }
  .upward_ad_solutions_orange {
    background-color: #EE9E07; }
  .upward_ad_solutions_purple {
    background-color: #63619D; }
  .upward_ad_solutions_green {
    background-color: #A0C8A9; }
  .upward_ad_solutions_red {
    background-color: #EB5757; }
  .upward_ad_solutions_blue {
    background-color: #2D9CDB; }

.upward_publisher_solutions {
  padding: 100px 0 0;
  font-size: 18px; }
  .upward_publisher_solutions .language_wrapper {
    margin-bottom: 100px; }
    .upward_publisher_solutions .language_wrapper .solution-data {
      margin-bottom: 50px; }
    .upward_publisher_solutions .language_wrapper.partner h3 {
      margin-bottom: 25px; }
    .upward_publisher_solutions .language_wrapper.partner p {
      margin-bottom: 35px; }
  .upward_publisher_solutions h3 {
    font-weight: 300;
    font-size: 48px;
    line-height: 105.6%;
    padding-top: 20px;
    margin-bottom: 55px; }
  .upward_publisher_solutions h4 {
    font-weight: bold;
    margin-bottom: 15px;
    font-size: 22px; }
  .upward_publisher_solutions p {
    line-height: 22px; }
  @media (max-width: 1199.98px) {
    .upward_publisher_solutions {
      text-align: center; } }

.upward_partner_logos .container {
  padding: 0; }
  .upward_partner_logos .container .vertical_logo_image_wrapper {
    position: relative;
    text-align: center;
    margin: 0 auto;
    overflow: hidden; }
    .upward_partner_logos .container .vertical_logo_image_wrapper .slick-arrow {
      display: none !important; }
    .upward_partner_logos .container .vertical_logo_image_wrapper img {
      max-height: 45px;
      margin: 10px 20px 30px 0;
      filter: gray;
      /* IE6-9 */
      -webkit-filter: grayscale(1);
      /* Google Chrome, Safari 6+ & Opera 15+ */
      filter: grayscale(1); }
      .upward_partner_logos .container .vertical_logo_image_wrapper img.logos_grayscale {
        filter: gray;
        /* IE6-9 */
        -webkit-filter: grayscale(1);
        /* Google Chrome, Safari 6+ & Opera 15+ */
        filter: grayscale(1); }

@media (max-width: 767.98px) {
  .upward_partner_logos .vertical_logo_image_wrapper {
    width: 100%; }
  .upward_partner_logos .upward_partner_logos {
    padding: 75px 0; }
    .upward_partner_logos .upward_partner_logos .vertical_logo_image_wrapper img {
      margin-bottom: 40px; } }

.pricing-overlay .modal-content, .pricing-overlay .modal-body {
  padding: 0; }

.pricing-overlay section {
  padding: 50px 95px; }

.pricing-overlay .pricing_post_a_job h3 {
  font-size: 48px;
  margin-bottom: 30px; }
  .pricing-overlay .pricing_post_a_job h3 span {
    border-bottom: 3px solid #EE9E07; }

.pricing-overlay .pricing_post_a_job p {
  font-size: 18px;
  line-height: 105.6%; }

.pricing-overlay .pricing_post_a_job a.btn {
  width: 150px;
  font-size: 16px;
  margin: 0 auto; }

.pricing-overlay .pricing_how_it_works {
  background-color: #F8F8F8; }
  .pricing-overlay .pricing_how_it_works.upward_vertical_getall h3 {
    margin-bottom: 25px; }
  .pricing-overlay .pricing_how_it_works h3 {
    font-size: 48px; }
  .pricing-overlay .pricing_how_it_works div h4 {
    font-size: 42px;
    color: #EE9E07; }
  .pricing-overlay .pricing_how_it_works div h4:before, .pricing-overlay .pricing_how_it_works div h4:after {
    background: #F9E6C5 !important; }
  .pricing-overlay .pricing_how_it_works div p {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 20px; }
  .pricing-overlay .pricing_how_it_works div a {
    margin-bottom: 20px; }

.pricing-overlay .upward_post_boards h3 {
  margin-bottom: 40px; }

.pricing-overlay .upward_post_boards .top_board_image_holder {
  max-width: 440px;
  margin: 0 auto; }

.pricing-overlay .upward_post_boards .take_the_wheel {
  max-width: 540px;
  margin: 50px auto 0; }
  .pricing-overlay .upward_post_boards .take_the_wheel p {
    font-weight: 300;
    font-size: 24px;
    line-height: 105.6%; }
  .pricing-overlay .upward_post_boards .take_the_wheel a {
    width: 200px;
    margin: 40px auto 0;
    font-size: 16px; }

@media (max-width: 1199.98px) {
  .pricing-overlay .upward_post_boards .take_the_wheel p {
    font-size: 18px; } }

.pricing-overlay .abstract_wrapper svg {
  fill: #f9e6c5; }

.pricing-overlay .pricing_faqs {
  border-radius: 0 0 25px 25px;
  background-color: #F9E6C5; }
  .pricing-overlay .pricing_faqs h4 {
    font-size: 48px; }
  .pricing-overlay .pricing_faqs p {
    font-size: 18px;
    line-height: 112.6%;
    margin-bottom: 50px; }
    .pricing-overlay .pricing_faqs p span {
      color: #EE9E07; }
  .pricing-overlay .pricing_faqs a {
    text-decoration: underline; }
  .pricing-overlay .pricing_faqs .moreFAQS {
    font-size: 18px;
    font-weight: bold;
    color: #EE9E07;
    text-decoration: none; }

@media (max-width: 1199.98px) {
  .pricing-overlay section {
    padding: 50px 35px; }
  .pricing-overlay a {
    white-space: nowrap; }
  .pricing-overlay .pricing_post_a_job {
    padding-bottom: 0; }
    .pricing-overlay .pricing_post_a_job h3 {
      font-size: 48px;
      margin-bottom: 50px; }
    .pricing-overlay .pricing_post_a_job .price_range_text {
      margin: 50px 0; }
  .pricing-overlay .pricing_how_it_works h4 {
    font-size: 35px; }
  .pricing-overlay .pricing_how_it_works div h5 {
    font-size: 35px;
    margin-bottom: 25px; } }

@media (max-width: 1199.98px) {
  div[tabindex] {
    z-index: 999999 !important; } }

.upward_vertical_recent_items {
  padding: 54px 0; }
  .upward_vertical_recent_items h3 {
    font-size: 48px;
    margin-bottom: 60px; }
  .upward_vertical_recent_items .recent_item {
    padding: 22px;
    position: relative;
    background: #ffffff;
    font-family: "apercu-light";
    color: #2E2210;
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    margin-bottom: 1rem; }
    .upward_vertical_recent_items .recent_item .recent_icon {
      position: absolute;
      top: 13px;
      left: 15px;
      font-size: 1.25rem;
      color: #EE9E07; }
    .upward_vertical_recent_items .recent_item header {
      font-weight: bold;
      margin-bottom: 24px;
      font-size: 12px;
      letter-spacing: 0.03em;
      text-transform: uppercase; }
    .upward_vertical_recent_items .recent_item span.recent_time {
      position: absolute;
      right: 15px;
      top: 15px;
      padding: 8px 14px 7px;
      background-color: #34302a;
      text-transform: none;
      color: #fff;
      border-radius: 30px; }
    .upward_vertical_recent_items .recent_item .recent_link a {
      color: #EE9E07;
      font-size: 14px;
      text-decoration: none;
      border-bottom: 1px solid #EE9E07;
      padding-bottom: 2px; }
      .upward_vertical_recent_items .recent_item .recent_link a:hover {
        border-bottom-width: 0; }
    .upward_vertical_recent_items .recent_item section {
      position: relative; }
      .upward_vertical_recent_items .recent_item section.recent_has_image {
        padding-left: 75px; }
        .upward_vertical_recent_items .recent_item section.recent_has_image .recent_item_image {
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 50px; }
          .upward_vertical_recent_items .recent_item section.recent_has_image .recent_item_image img {
            max-width: 100%; }
          .upward_vertical_recent_items .recent_item section.recent_has_image .recent_item_image svg {
            width: 100%; }
    .upward_vertical_recent_items .recent_item h4 {
      font-size: 18px;
      margin-bottom: 2px;
      font-family: "apercu-light"; }
    .upward_vertical_recent_items .recent_item h5 {
      margin-bottom: 2px; }
    .upward_vertical_recent_items .recent_item h6 {
      margin: 0; }
    .upward_vertical_recent_items .recent_item p {
      margin-bottom: 0;
      line-height: 1.2; }
    .upward_vertical_recent_items .recent_item_parent {
      transition: all 800ms ease; }
      @media (prefers-reduced-motion: reduce) {
        .upward_vertical_recent_items .recent_item_parent {
          transition: none; } }
    .upward_vertical_recent_items .recent_item-enter {
      opacity: 0;
      transform: scale(0); }
    .upward_vertical_recent_items .recent_item-enter-active {
      opacity: 0.7;
      transform: scale(1.1); }
    .upward_vertical_recent_items .recent_item-enter-done {
      opacity: 1;
      transform: scale(1); }
    .upward_vertical_recent_items .recent_item-exit {
      display: none; }
    .upward_vertical_recent_items .recent_item .recent_candidate {
      padding-left: 30px; }
      .upward_vertical_recent_items .recent_item .recent_candidate span.recent_time {
        background-color: #56CCF2; }
      .upward_vertical_recent_items .recent_item .recent_candidate .recent_icon {
        color: #2D9CDB; }
        .upward_vertical_recent_items .recent_item .recent_candidate .recent_icon svg {
          fill: #2D9CDB; }
      .upward_vertical_recent_items .recent_item .recent_candidate h5 {
        font-size: 12px;
        color: #543e1d; }
      .upward_vertical_recent_items .recent_item .recent_candidate .recent_link a {
        color: #2D9CDB;
        border-bottom-color: #2D9CDB; }

@keyframes recentItemFade {
  0% {
    transform: scale(1);
    opacity: 1; }
  40% {
    transform: scale(1);
    opacity: 1; }
  99% {
    transform: scale(0);
    opacity: 0; }
  100% {
    transform: scale(0);
    opacity: 0;
    display: none; } }

.upward_main .recent_item-enter-done, .upward_homepage_main .recent_item-enter-done {
  animation: recentItemFade 5s ease forwards;
  animation-iteration-count: 1; }

.upward_main .recent_item-exit, .upward_homepage_main .recent_item-exit {
  display: block; }

.upward_main .recent_item-exit, .upward_homepage_main .recent_item-exit {
  display: none; }

.rounded_modal {
  margin-top: 120px; }
  .rounded_modal button.modalClose.close {
    position: absolute;
    z-index: 10;
    top: 1rem;
    right: 1.5rem;
    font-size: 2rem; }
  .rounded_modal .modal-content {
    border-radius: 24px;
    padding: 48px; }
    .rounded_modal .modal-content .modal-header {
      padding: 0;
      padding-bottom: 2rem; }
      .rounded_modal .modal-content .modal-header .modal-title {
        font-size: 48px;
        line-height: 1;
        margin: 0 auto; }
    .rounded_modal .modal-content .modal-header, .rounded_modal .modal-content .modal-footer {
      border: 0;
      text-align: center; }
    .rounded_modal .modal-content .modal-footer {
      display: block; }
    @media (max-width: 767.98px) {
      .rounded_modal .modal-content {
        padding: 30px; } }

.signin-modal .modal-content {
  padding: 50px 20px 0; }
  .signin-modal .modal-content .btn-reset {
    color: #adb5bd;
    box-shadow: none; }
  .signin-modal .modal-content .custom-control-label {
    padding-top: 2px; }

@media (max-width: 1199.98px) {
  .signin-modal .modal-content {
    padding: 50px 0 0; } }

.fixedButtonWrapper {
  width: 150px;
  float: left; }

.jobTypeBtn {
  margin-top: 60px;
  padding: 6px 12px;
  text-decoration: underline;
  font-size: 17px;
  cursor: pointer; }

.loadMoreBtn {
  float: right;
  padding: 6px 20px;
  text-decoration: underline;
  font-size: 17px;
  cursor: pointer; }

.outline-none {
  outline: 0 !important; }
  .outline-none:focus {
    outline: 0 !important; }

.btn.upward_actions_button {
  background-color: #fff;
  color: #78838e;
  border: 1px solid #ced4da;
  line-height: 1.5;
  padding: 0.25rem 0.7rem;
  border-radius: 0.3rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn.upward_actions_button {
      transition: none; } }
  .btn.upward_actions_button:focus {
    color: #495057;
    background-color: #fff;
    border-color: #9ec2f3;
    outline: 0;
    box-shadow: 0 0 0 0.02rem rgba(44, 123, 229, 0.25); }
  .btn.upward_actions_button span {
    margin-right: -3px;
    border-left: 1px solid #cbd0d4;
    padding: 0.05rem 0 0 0.4rem;
    line-height: 0.4em;
    height: 14px;
    color: #b0b6bc;
    float: right;
    margin-top: 0.2rem; }
  .btn.upward_actions_button:hover {
    border-color: #b1bbc4; }
    .btn.upward_actions_button:hover span {
      color: #606a73; }
  .btn.upward_actions_button:after {
    display: none; }

.btn-rounded {
  border-radius: 40px; }

.btn-xl {
  padding: 0.7rem 1.4rem;
  font-size: 1.2rem;
  font-weight: bold; }

.btn-dark-orange {
  color: #fff; }

.textResumeToolbarButton {
  background-color: #ebebeb;
  border-color: #E5E5E5;
  color: black; }

.dropboxButton {
  margin-top: 0rem; }

@media only screen and (min-width: 992px) {
  .dropboxButton {
    margin-top: 0.5rem; } }

@media only screen and (min-width: 1200px) {
  .dropboxButton {
    margin-top: 0rem; } }

b,
strong {
  font-weight: normal;
  font-family: "apercu-regular"; }

h2 {
  font-size: 68px; }
  @media (max-width: 991.98px) {
    h2 {
      font-size: 48px; } }

h3 {
  font-size: 48px; }
  @media (max-width: 991.98px) {
    h3 {
      font-size: 36px; } }

h4 {
  font-size: 30px; }
  @media (max-width: 991.98px) {
    h4 {
      font-size: 22px; } }

.fade-effect-enter {
  opacity: 0.2; }

.fade-effect-enter-done {
  opacity: 1;
  transition: opacity 1200ms ease-in; }

.fade-effect-exit {
  opacity: 1; }

.fade-effect-exit-done {
  opacity: 0.2;
  transition: opacity 1200ms ease-in; }

.more_details_img {
  position: relative;
  top: 48px;
  opacity: 0.5;
  transition: all 1000ms ease-in; }
  @media (prefers-reduced-motion: reduce) {
    .more_details_img {
      transition: none; } }
  .more_details_img-enter-done {
    top: 0;
    opacity: 1; }
  .more_details_img-exit-done {
    top: 48px;
    opacity: 0; }

@keyframes infiniteZoomInZoomOut {
  0% {
    transform: scale(1); }
  20% {
    transform: scale(1.1); }
  80% {
    transform: scale(0.9); }
  100% {
    transform: scale(1); } }

.image_zoom_in_out {
  animation: infiniteZoomInZoomOut 60s ease-in-out infinite; }

.react-autosuggest__container {
  position: relative; }
  .react-autosuggest__container .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
  .react-autosuggest__container .react-autosuggest__suggestions-container {
    display: none;
    /* padding: $input-padding-y $input-padding-x; */ }
  .react-autosuggest__container .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 100%;
    width: 100%;
    border: 1px solid #ccc;
    background-color: #fff;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2; }
  .react-autosuggest__container .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none; }
  .react-autosuggest__container .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 0.25rem 0.7rem;
    margin: -1px 0;
    border: 1px solid #f8f9fa;
    border-left: 0;
    border-right: 0; }
    .react-autosuggest__container .react-autosuggest__suggestion:last-child {
      border-bottom: 0;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      margin-bottom: 0; }
  .react-autosuggest__container .react-autosuggest__suggestion--highlighted {
    color: #495057;
    background-color: #f8f9fa;
    cursor: pointer; }

#pp-notification {
  position: fixed !important;
  bottom: 0 !important;
  width: 100% !important;
  z-index: 5000 !important;
  text-align: center !important;
  margin-bottom: 0 !important;
  border-color: #cdcdcd !important;
  padding-bottom: 13px !important;
  padding-top: 15px !important;
  border-radius: 0 !important;
  border-top: 1px solid #dedede !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 0 !important; }

.pp-banner-text {
  font-size: 12px !important;
  line-height: 18px !important;
  color: #2c2c2c !important;
  font-weight: bold !important; }

.clean-text {
  text-rendering: optimizeLegibility;
  text-rendering: geometricPrecision;
  font-smooth: always;
  font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased; }

#pp_modal_close_x {
  font-weight: bold;
  opacity: 0.9;
  font-size: 1.375em;
  line-height: 1;
  position: absolute;
  top: 15px;
  right: 0.4em;
  color: #aaaaaa;
  cursor: pointer; }

.alert-warning {
  background-color: #fcf8e3;
  border-color: #faebcc;
  color: #8a6d3b; }
